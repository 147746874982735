import { cn } from '@shadcn/utils';
import type { PenaltyData } from 'app/types/penalty-data';
import { formatNumber } from 'app/utils/formatters';
import { NoDataFound } from './NoDataFound';

type UtilitiesSummaryProps = {
  yearsData?: PenaltyData['yearsData'];
  estimatedAnnual?: PenaltyData['estimatedAnnual'];
  theme: any;
};

export const UtilitiesSummary = ({ yearsData = [], estimatedAnnual, theme }: UtilitiesSummaryProps) => {
  if (!yearsData.length && !estimatedAnnual) {
    return (
      <div className="mb-8 min-h-[200px] overflow-x-auto rounded-xl bg-white/10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
        <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
          Utility Bills Summary
        </h2>
        <NoDataFound
          message="No Utility Bills Found"
          submessage="Upload utility bills for this project to see the summary."
        />
      </div>
    );
  }

  return (
    <div className="mb-8 overflow-x-auto rounded-xl bg-white/10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
      <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
        Utility Bills Summary
      </h2>
      <table className="w-full text-left">
        <thead>
          <tr className="border-b border-gray-700">
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Year
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Electricity (kWh)
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Electricity (tCO₂)
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Natural Gas (Therms)
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Natural Gas (tCO₂)
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Steam (mLbs)
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Steam (tCO₂)
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Oil (gallons)
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Oil (tCO₂)
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Total Emissions (tCO₂)
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Site EUI
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Source EUI
            </th>
            <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
              Months Covered
            </th>
          </tr>
        </thead>
        <tbody>
          {yearsData?.map((year, index) => (
            <tr
              key={year.year}
              className={cn({
                'bg-white/5': index % 2 === 0,
              })}
            >
              <td className="p-2 text-sm font-medium" style={{ color: theme.text.primary }}>
                {year.year}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.energySums.electric)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.emissionsByType.electric)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.energySums.gas)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.emissionsByType.gas)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.energySums.steam)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.emissionsByType.steam)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.energySums.oil)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.emissionsByType.oil)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.actualEmissions)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.eui.siteEUI)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {formatNumber(year.eui.sourceEUI)}
              </td>
              <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                {year.monthsCovered}
              </td>
            </tr>
          ))}
          <tr className="bg-blue-500/10 font-semibold">
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              Estimated Annual
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.energySums.electric ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.emissionsByType.electric ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.energySums.gas ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.emissionsByType.gas ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.energySums.steam ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.emissionsByType.steam ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.energySums.oil ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.emissionsByType.oil ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.emissions ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.eui.siteEUI ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              {formatNumber(estimatedAnnual?.eui.sourceEUI ?? 0)}
            </td>
            <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
              12
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
