import { AppLayoutProvider } from 'app/utils/AppLayout';
import { LoaderCircleIcon } from 'lucide-react';
import React, { PropsWithChildren, useState } from 'react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

export const AppLayout = (props: PropsWithChildren<Record<never, never>>) => {
  const { isLoading } = useJumboAuth();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <AppLayoutProvider value={{ sidebarOpen, setSidebarOpen }}>
      {isLoading ? <LoaderCircleIcon className="m-auto h-[10vmin] w-[10vmin] animate-spin" /> : props.children}
    </AppLayoutProvider>
  );
};
