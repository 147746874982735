import { SoloPage } from 'app/layouts/solo-page/SoloPage';

// TODO: fix types
export const Page = ({ Component, Layout = SoloPage, componentProps = {}, layoutProps = {} }) => {
  return (
    <Layout {...layoutProps}>
      <Component {...componentProps} />
    </Layout>
  );
};
