import { useState } from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { isAxiosError } from 'app/utils/appHelpers';
import { Button } from '@shadcn/ui';
import { FormikInputWrapper } from '@shadcn/custom/FormikInputWrapper';
import { AuthPageShell } from 'app/pages/auth-pages/AuthPageShell';
import authServices from 'app/services/auth-services';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

export const ResetPassword = () => {
  const [serverMessage, setServerMessage] = useState('');

  const onSubmit = async (email) => {
    try {
      await authServices.forgotPassword(email);
      setServerMessage('Reset link sent. Please check your email! (Note that this may take a few minutes.)');
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data?.errors;
        const errorObject = errors[0];
        const message = errorObject?.detail || 'Something went wrong. Please try again!';
        setServerMessage(message);
      }
    }
  };

  const renderForm = () => (
    <div className="flex h-full min-w-0 flex-col">
      <Formik
        validateOnChange={true}
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(data.email);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="text-left" noValidate autoComplete="off">
            <FormikInputWrapper className="mt-2 w-full" name="email" label="Email" type="email" />
            {serverMessage ? <p className="my-4">{serverMessage}</p> : null}
            <Button className="mt-6 w-full" type="submit" size="lg" loading={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      <h5 className="mt-4 text-center">
        Enter your account email. We'll send you instructions to reset your password.
      </h5>
    </div>
  );

  return <AuthPageShell rightContent={renderForm()} />;
};
