import { Fragment } from 'react';
import { useRoutes } from 'react-router-dom';

import routes from './routes';

const AppRoutes = () => {
  const appRoutes = useRoutes(routes);

  return <Fragment>{appRoutes}</Fragment>;
};
export default AppRoutes;
