import { ReactNode } from 'react';

export const FeaturesList = ({ features }: { features: { icon: ReactNode; text: string }[] }) => {
  return (
    <ul className="mb-8 flex flex-col gap-4 px-4 text-sm text-primary-foreground">
      {features.map((f) => {
        return (
          <li className="grid grid-cols-[auto_1fr] items-center gap-4" key={f.text}>
            {f.icon} {f.text}
          </li>
        );
      })}
    </ul>
  );
};
