import { Dispatch, forwardRef, SetStateAction } from 'react';
import { Checkbox, ScrollArea, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcn/ui';
import { FileWithMetadata } from './DocumentUpload';

interface EnterDocumentInfoProps {
  files: FileWithMetadata[];
  utilityProvider: string;
  setUtilityProvider: Dispatch<SetStateAction<string>>;
  handleMultipleBillsChange: (index: number) => void;
}

export const EnterDocumentInfo = forwardRef<HTMLDivElement, EnterDocumentInfoProps>(
  ({ files, handleMultipleBillsChange }, ref) => {
    return (
      <ScrollArea ref={ref} orientation="vertical" className="flex flex-col gap-4 p-2">
        {files.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>File Name</TableHead>
                <TableHead className="text-right">Multiple Bills?</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {files.map((file, index) => (
                <TableRow key={index}>
                  <TableCell scope="row">{file.fileName}</TableCell>
                  <TableCell className="p-0 text-right">
                    <Checkbox
                      className="mr-4"
                      checked={file.hasMultipleBills}
                      onCheckedChange={() => handleMultipleBillsChange(index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </ScrollArea>
    );
  }
);
