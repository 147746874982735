import { useEffect } from 'react';
import { Toaster, TooltipProvider } from '@shadcn/ui';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AppLayout } from './AppLayout';
import AppRoutes from './AppRoutes';
import { config } from './config/main';
import { JumboAuthProvider } from '@jumbo/components/JumboAuthProvider/JumboAuthProvider';
import { initializeGoogleAnalytics } from './utils/google-analytics';

function App() {
  const GOOGLE_OAUTH_2_CLIENT_ID = config.env.GOOGLE_OAUTH_2_CLIENT_ID ?? '';
  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={GOOGLE_OAUTH_2_CLIENT_ID}>
        <JumboAuthProvider>
          <AppLayout>
            <TooltipProvider delayDuration={0}>
              <Toaster />
              <AppRoutes />
            </TooltipProvider>
          </AppLayout>
        </JumboAuthProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default App;
