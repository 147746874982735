import { FileVideo2Icon, FilmIcon, UserRoundPenIcon } from 'lucide-react';

const plans = {
  basic: [
    {
      text: '3 Edits per month (Trim, Clip, Convert to GIF)',
      icon: <FilmIcon fontSize={'small'} />,
    },
    {
      text: '6 Uploads per month',
      icon: <FileVideo2Icon />,
    },
  ],
  pro: [
    {
      text: '30 Edits per month (Trim, Clip, Convert to GIF)',
      icon: <FilmIcon fontSize={'small'} />,
    },
    {
      text: '60 Uploads per month',
      icon: <FileVideo2Icon />,
    },
    {
      text: 'Invite 1 user to collaborate, $14/user',
      icon: <UserRoundPenIcon />,
    },
  ],
  enterprise: [
    {
      text: '180 Edits per month (Trim, Clip, Convert to GIF)',
      icon: <FilmIcon fontSize={'small'} />,
    },
    {
      text: '360 Uploads per month',
      icon: <FileVideo2Icon />,
    },
    {
      text: 'Invite unlimited users to collaborate, $20/user',
      icon: <UserRoundPenIcon />,
    },
  ],
};

export type Plan = keyof typeof plans;

const featuresServices = {
  getFeaturesList: (plan: Plan) => {
    return plans[plan];
  },
};

export default featuresServices;
