import { cn } from '@shadcn/utils';
import { Children, cloneElement, forwardRef, Fragment, isValidElement, ReactNode } from 'react';
import { TabsProps, TabsListProps, TabsTriggerProps, TabsContentProps } from '@radix-ui/react-tabs';
import { Tabs, TabsContent, TabsTrigger, TabsList } from '@shadcn/ui';

export const Stepper = forwardRef<HTMLDivElement, TabsProps>((props, ref) => {
  return <Tabs ref={ref} {...props} />;
});

export const StepperList = forwardRef<HTMLDivElement, TabsListProps>(({ children, ...props }, ref) => {
  const clones = Children.map(children, (child, index) => {
    return isValidElement<{ className: string; children: ReactNode; key?: string | number }>(child)
      ? cloneElement(child, {
          ...child.props,
          className: cn('text-sm font-medium group', child.props.className),
          children: (
            <>
              <span className="mr-1 flex h-6 w-6 items-center justify-center rounded-full bg-secondary p-1 group-data-[state=active]:bg-primary">
                {index + 1}
              </span>{' '}
              {child.props.children}
            </>
          ),
        })
      : child;
  });

  return (
    <TabsList className="bg-transparent" ref={ref} {...props}>
      {clones
        ? Array.from(clones).map((child, index) => {
            return (
              <Fragment key={index}>
                {child}
                {index < Array.from(clones).length - 1 ? <span key={index} className="h-[1px] w-5 bg-ring" /> : null}
              </Fragment>
            );
          })
        : null}
    </TabsList>
  );
});

export const StepLabel = forwardRef<HTMLButtonElement, TabsTriggerProps>(({ className, ...props }, ref) => {
  return <TabsTrigger className={cn('pointer-events-none bg-transparent', className)} ref={ref} {...props} />;
});

export const StepperContent = forwardRef<HTMLDivElement, TabsContentProps>((props, ref) => {
  return <TabsContent ref={ref} {...props} />;
});
