import { cn } from '@shadcn/utils';
import { PropsWithChildren } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui';

interface PricePlanProps {
  title: string;
  subheader: string;
  isSelected: boolean;
}

export const PricePlan = ({ title, subheader, children, isSelected }: PropsWithChildren<PricePlanProps>) => {
  return (
    <Card
      className={cn('overflow-hidden rounded-xl border-border bg-accent/50 transition-transform hover:scale-105', {
        'scale-105 border-primary bg-gradient-to-r from-primary to-primary-alt': isSelected,
      })}
    >
      <CardHeader
        className={cn('bg-primary text-center', {
          'bg-muted/30': isSelected,
        })}
      >
        <CardTitle
          className={cn('text-4xl', {
            'text-foreground': isSelected,
          })}
        >
          {title}
        </CardTitle>
        <CardDescription
          className={cn('text-xl', {
            'text-foreground': isSelected,
          })}
        >
          {subheader}
        </CardDescription>
      </CardHeader>
      <CardContent
        className={cn('flex flex-col items-center pt-10', {
          'bg-transparent': isSelected,
        })}
      >
        {children}
      </CardContent>
    </Card>
  );
};
