import { ReactNode } from 'react';
import { Input, InputProps, Label } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import * as React from 'react';

type InputWrapperProps = InputProps & {
  label?: ReactNode;
  error?: boolean;
  helperText?: string;
  inputStartAdornment?: ReactNode;
  inputEndAdornment?: ReactNode;
};

export const InputWrapper = React.forwardRef<HTMLInputElement, InputWrapperProps>(
  ({ className, inputStartAdornment, inputEndAdornment, label, error, helperText, ...props }, ref) => {
    const inputComponent = (
      <Input
        {...props}
        className={cn(
          {
            'border border-destructive focus-visible:outline-none focus-visible:ring-destructive': error,
          },
          className
        )}
        ref={ref}
      />
    );

    return (
      <div className="flex flex-col gap-2">
        {label ? <Label className="col-start-2">{label}</Label> : null}
        <div className="flex flex-row content-start items-center gap-2">
          {inputStartAdornment} {inputComponent} {inputEndAdornment}
        </div>
        {helperText ? (
          <p
            className={cn('col-span-full text-destructive-foreground', {
              'bg-destructive/25': error,
            })}
          >
            {helperText}
          </p>
        ) : null}
      </div>
    );
  }
);
InputWrapper.displayName = 'InputWrapper';
