import { defaultPrompts } from 'app/utils/promptHelpers';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface RightSidebarProps {
  handlePromptClick: (
    text: string,
    aiResponse: string,
    action?: () => Promise<void>,
    showTable?: boolean,
    showEnergyStarProperties?: boolean
  ) => void;
  handleSyncWithEnergyStarClick: () => Promise<void>;
  selectedTask?: {
    title: string;
    description: string;
  } | null;
}

export const RightSidebar = ({ handlePromptClick, handleSyncWithEnergyStarClick, selectedTask }: RightSidebarProps) => {
  return (
    <div className="w-[400px] border-l border-border bg-card p-6">
      <div className="space-y-6">
        {selectedTask && (
          <div className="mb-6 space-y-2 rounded-lg border border-border bg-background p-4">
            <h3 className="font-semibold">{selectedTask.title}</h3>
            <div className="prose prose-sm prose-invert max-w-none">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  h1: ({ node, ...props }) => <h1 className="mb-2 mt-4 text-xl font-bold" {...props} />,
                  h2: ({ node, ...props }) => <h2 className="mb-2 mt-3 text-lg font-semibold" {...props} />,
                  h3: ({ node, ...props }) => <h3 className="mb-1 mt-2 text-base font-medium" {...props} />,
                  ul: ({ node, ...props }) => <ul className="my-2 list-disc pl-4" {...props} />,
                  li: ({ node, ...props }) => <li className="my-1 text-sm text-muted-foreground" {...props} />,
                  p: ({ node, ...props }) => <p className="my-2 text-sm text-muted-foreground" {...props} />,
                  strong: ({ node, ...props }) => <strong className="font-semibold text-primary" {...props} />,
                }}
              >
                {selectedTask.description}
              </ReactMarkdown>
            </div>
          </div>
        )}

        {/* Action Buttons */}
        <div className="space-y-2">
          {defaultPrompts.map((prompt) => (
            <button
              key={prompt.text}
              onClick={() =>
                handlePromptClick(
                  prompt.text,
                  prompt.text,
                  prompt.text.toLowerCase().includes('sync') ? handleSyncWithEnergyStarClick : undefined,
                  prompt.showTable || false,
                  prompt.showEnergyStarProperties || false
                )
              }
              className="w-full rounded-lg border border-border bg-background p-3 text-left hover:bg-muted"
            >
              {prompt.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
