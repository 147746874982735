export const theme = {
  background: 'hsl(var(--background))',
  text: {
    primary: 'hsl(var(--foreground))',
    secondary: 'hsl(var(--muted-foreground))',
    highlight: 'hsl(var(--primary))',
  },
  card: {
    border: '1px solid transparent',
    borderColor: 'hsl(var(--border))',
    background: 'hsl(var(--card))',
    highlight: 'hsl(var(--muted-foreground))',
  },
  chart: {
    colors: [
      'hsl(var(--chart-1))',
      'hsl(var(--chart-2))',
      'hsl(var(--chart-3))',
      'hsl(var(--chart-4))',
      'hsl(var(--chart-5))',
    ],
  },
};
