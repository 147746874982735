import { ReactNode } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { cn } from '@shadcn/utils';
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList } from '@shadcn/ui';

export type Menu = {
  label: string;
  type: 'section';
  children: {
    uri: string;
    label: string;
    type: 'nav-item';
    icon?: ReactNode;
  }[];
}[];

const NavItem = ({
  item,
  collapsed,
  className,
}: {
  item: Menu[0]['children'][0];
  collapsed?: boolean;
  className?: string;
}) => {
  const l = useLocation();

  const isActive = l.pathname === item.uri;

  return (
    <NavigationMenuLink
      active={isActive}
      asChild
      className={cn(
        // if we expect multiple navs for some reason,
        // determine which styles should be here and which should be further down
        'items-center rounded-full rounded-l py-2 hover:bg-primary/40',
        {
          'px-4': !collapsed,
          'bg-primary': isActive,
        },
        className
      )}
    >
      <RouterLink to={item.uri} className="flex flex-row gap-4 no-underline">
        {item.icon ?? null} {!collapsed ? item.label : null}
      </RouterLink>
    </NavigationMenuLink>
  );
};

const SectionItem = ({ item }: { item: Menu[0] }) => {
  return (
    <NavigationMenuItem className="flex flex-col gap-2">
      <h4 className={cn('ml-10 text-xs uppercase text-muted-foreground', {})}>{item.label}</h4>
      {item.children.map((child) => {
        return <NavItem key={child.label} item={child} className="pl-10 pr-16" />;
      })}
    </NavigationMenuItem>
  );
};

const MenuTree = ({ items }: { items: Menu }) => {
  return items.map((item, index) => <SectionItem key={index} item={item} />);
};

export const VerticalNavbar = ({ items = [] }: { items: Menu }) => (
  <>
    <NavigationMenu orientation="vertical" className="items-start justify-stretch">
      <NavigationMenuList className="flex-col items-stretch gap-4">
        <MenuTree items={items} />
      </NavigationMenuList>
    </NavigationMenu>
  </>
);
