import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface AppLayout {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export const AppLayoutContext = createContext<AppLayout>({
  sidebarOpen: true,
  setSidebarOpen: () => {
    throw new Error('Not implemented');
  },
});

export const AppLayoutProvider = AppLayoutContext.Provider;

export const useAppLayout = () => useContext(AppLayoutContext);
