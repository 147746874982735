import jwtAuthAxios from './auth/jwtAuth';

const userServices = {
  update: async (userParams) => {
    const { data } = await jwtAuthAxios.put(`/v1/users/${userParams.id}`, userParams.data);
    return data;
  },
  invite: async (params) => {
    const { data } = await jwtAuthAxios.post('/v1/users/invite', params);
    return data;
  },
};

export default userServices;
