import { forwardRef, Fragment } from 'react';
import { Button, Progress, ScrollArea } from '@shadcn/ui';
import { FileWithMetadata } from 'app/pages/project/tabs/document-steps/DocumentUpload';
import { FileTextIcon } from 'lucide-react';

interface ReviewUploadProps {
  utilityProvider: string;
  files: FileWithMetadata[];
  billBreaks: Record<string, number[]>;
  uploadProgress: Record<string, number>;
  isUploading: boolean;
  handleUpload: () => void;
}

export const ReviewUpload = forwardRef<HTMLDivElement, ReviewUploadProps>(
  ({ uploadProgress, handleUpload, isUploading, utilityProvider, files, billBreaks }, ref) => {
    return (
      <ScrollArea ref={ref} orientation="vertical" className="flex flex-col items-start gap-4 p-2">
        <h4 className="font-bold">Review Your Upload</h4>
        <div className="grid w-fit auto-cols-auto grid-cols-2 items-start gap-x-2 gap-y-1">
          <p>Utility Provider:</p>
          <p>{utilityProvider}</p>
          <p>Number of Files:</p>
          <p>{files.length}</p>
          {files.map((file, index) => (
            <Fragment key={file.fileName || index}>
              <p className="col-span-full mt-4 inline-flex items-center gap-2 font-bold underline">
                <FileTextIcon /> {file.fileName}
              </p>
              <p>Size:</p>
              <p>{(file.fileSize / 1024 / 1024).toFixed(2)} MB</p>
              <p>Pages:</p>
              <p>{file.numberOfPages}</p>
              <p>Multiple Bills:</p>
              <p>{file.hasMultipleBills ? 'Yes' : 'No'}</p>
              {file.hasMultipleBills ? (
                <>
                  <p>Bill Breaks</p>
                  <p>{billBreaks[file.fileName]?.join(', ') || 'None'}</p>
                </>
              ) : null}
              {isUploading ? (
                <div className="col-span-full mt-4 flex items-center">
                  <Progress className="mr-2" value={uploadProgress[file.fileName] || 0} />
                  <p className="text-muted-foreground">{uploadProgress[file.fileName] || 0}%</p>
                </div>
              ) : null}
            </Fragment>
          ))}
          {!isUploading && (
            <Button variant="default" size="lg" onClick={handleUpload} className="mt-4">
              Upload Documents
            </Button>
          )}
        </div>
      </ScrollArea>
    );
  }
);
