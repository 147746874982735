import energyStarServices from './energy-star-services';

const syncWithEnergyStar = async (customerId: string, propertyId: string, parentId?: string) => {
  try {
    const project = await energyStarServices.syncProperty(customerId, propertyId, parentId);

    return project;
  } catch (error) {
    console.error('Error syncing with Energy Star:', error);
    throw error;
  }
};

const syncServices = {
  syncWithEnergyStar,
};

export default syncServices;
