import { useState, useMemo } from 'react';
import { format } from 'date-fns';
import { cn } from '@shadcn/utils';
import type { Meter, ConsumptionData } from 'app/types/energy-star';
import { NoDataFound } from '../project/NoDataFound';

interface MeterReading {
  startDate: string;
  endDate: string;
  usage: number;
  lastUpdated: string;
  lastUpdatedBy: string;
  meterName: string;
  meterType: string;
}

type YearReadingsMap = {
  [year: string]: MeterReading[];
};

type EnergyStarMetersProps = {
  meters: Meter[];
  consumptionData: { [key: string]: ConsumptionData };
  theme: any;
  displayEmptyMeters?: boolean;
};

export const EnergyStarMeters = ({
  meters = [],
  consumptionData = {},
  theme,
  displayEmptyMeters = true,
}: EnergyStarMetersProps) => {
  const [expandedMeters, setExpandedMeters] = useState<Set<string>>(new Set());
  const [expandedYears, setExpandedYears] = useState<Set<string>>(new Set());

  const meterReadingsMap = useMemo(() => {
    if (!meters || !consumptionData) return new Map();

    return meters.reduce((acc, meter) => {
      if (!meter?.id) return acc;

      const meterConsumption = consumptionData[meter.id]?.meterConsumption || [];

      // Skip meters with no consumption data if displayEmptyMeters is false
      if (!displayEmptyMeters && meterConsumption.length === 0) {
        return acc;
      }

      const readings = meterConsumption
        .map((reading) => ({
          ...reading,
          meterName: meter.name || 'Unknown',
          meterType: meter.type || 'Unknown',
          lastUpdated: reading?.lastUpdatedDate || reading?.endDate,
          lastUpdatedBy: reading?.lastUpdatedBy || 'System',
        }))
        .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime());

      acc.set(meter.id, { meter, readings });
      return acc;
    }, new Map());
  }, [meters, consumptionData, displayEmptyMeters]);

  const toggleMeter = (meterId: string) => {
    setExpandedMeters((prev) => {
      const next = new Set(prev);
      if (next.has(meterId)) {
        next.delete(meterId);
      } else {
        next.add(meterId);
      }
      return next;
    });
  };

  const toggleYear = (meterId: string, year: string) => {
    setExpandedYears((prev) => {
      const next = new Set(prev);
      const key = `${meterId}-${year}`;
      if (next.has(key)) {
        next.delete(key);
      } else {
        next.add(key);
      }
      return next;
    });
  };

  const groupReadingsByYear = (readings: MeterReading[]): YearReadingsMap => {
    return readings.reduce((acc: YearReadingsMap, reading) => {
      const year = new Date(reading.startDate).getFullYear();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(reading);
      return acc;
    }, {});
  };

  return (
    <div className="mb-8 overflow-x-auto rounded-xl bg-white/10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
      <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
        Meter Consumption History
      </h2>

      {Array.from(meterReadingsMap.values()).length > 0 ? (
        Array.from(meterReadingsMap.values()).map(({ meter, readings }) => (
          <div key={meter.id} className="mb-4 last:mb-0">
            <button
              onClick={() => toggleMeter(meter.id)}
              className="flex w-full items-center justify-between rounded-lg bg-white/5 p-4 hover:bg-white/10"
            >
              <h3 className="text-lg font-semibold" style={{ color: theme.text.primary }}>
                {meter.name}
              </h3>
              <span className="text-sm" style={{ color: theme.text.secondary }}>
                {expandedMeters.has(meter.id) ? '▼' : '▶'}
              </span>
            </button>

            {expandedMeters.has(meter.id) && (
              <div className="mt-2">
                {readings.length > 0 ? (
                  <div className="space-y-4">
                    {Object.entries(groupReadingsByYear(readings))
                      .sort(([yearA], [yearB]) => Number(yearB) - Number(yearA))
                      .map(([year, yearReadings]: [string, MeterReading[]]) => {
                        const yearKey = `${meter.id}-${year}`;
                        return (
                          <div key={year} className="ml-4 mt-2 border-l-2 border-white/10">
                            <button
                              onClick={() => toggleYear(meter.id, year)}
                              className="flex w-full items-center justify-between rounded-lg bg-white/5 p-3 pl-4 hover:bg-white/10"
                            >
                              <h4 className="text-md font-semibold" style={{ color: theme.text.primary }}>
                                {year}
                              </h4>
                              <div className="flex items-center gap-2">
                                <span className="text-sm text-gray-400">
                                  {yearReadings.length} reading{yearReadings.length !== 1 ? 's' : ''}
                                </span>
                                <span className="text-sm" style={{ color: theme.text.secondary }}>
                                  {expandedYears.has(yearKey) ? '▼' : '▶'}
                                </span>
                              </div>
                            </button>

                            {expandedYears.has(yearKey) && (
                              <div className="ml-4 mt-2">
                                <table className="w-full text-left">
                                  <thead>
                                    <tr className="border-b border-gray-700">
                                      <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                                        Period
                                      </th>
                                      <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                                        Usage
                                      </th>
                                      <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                                        Last Updated
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {yearReadings.map((reading: MeterReading, index: number) => (
                                      <tr
                                        key={index}
                                        className={cn({
                                          'bg-white/5': index % 2 === 0,
                                        })}
                                      >
                                        <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                                          <div>{format(new Date(reading.startDate), 'MMM d, yyyy')}</div>
                                          <div className="text-gray-500">to</div>
                                          <div>{format(new Date(reading.endDate), 'MMM d, yyyy')}</div>
                                        </td>
                                        <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                                          {reading.usage}
                                        </td>
                                        <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                                          <div>{format(new Date(reading.lastUpdated), 'MMM d, yyyy')}</div>
                                          <div className="text-gray-500">by {reading.lastUpdatedBy}</div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div className="p-4 text-sm" style={{ color: theme.text.secondary }}>
                    No consumption data available
                  </div>
                )}
              </div>
            )}
          </div>
        ))
      ) : (
        <NoDataFound
          message="No meter data available"
          submessage="Sync this project with a property in Energy Star to see meter data."
        />
      )}
    </div>
  );
};
