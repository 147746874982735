import { Button } from '@shadcn/ui/button';
import { Link } from 'react-router-dom';
import { ProjectInfoData } from 'app/services/project-services';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { cn } from '@shadcn/utils';

interface EnergyStarChildProperty {
  id: string;
  name: string;
  parentId?: string;
}

interface EnergyStarProperty {
  id: string;
  name: string;
  childProperties?: EnergyStarChildProperty[];
}

interface EnergyStarPropertiesTableProps {
  properties: EnergyStarProperty[];
  syncedProperties: Set<string>;
  projects: ProjectInfoData[];
  setRefreshProjects: Dispatch<SetStateAction<boolean>>;
  onPropertySync: (customerId: string, propertyId: string, parentId?: string) => void;
  onSnackbar: (message: string, variant: 'default' | 'destructive' | 'positive') => void;
}

export const EnergyStarPropertiesTable = ({
  properties,
  setRefreshProjects,
  projects,
  onPropertySync,
}: EnergyStarPropertiesTableProps) => {
  const { authUser } = useJumboAuth();

  const matchedProperties = useMemo(() => {
    const projectMap = new Map<string, string>(); // energyStarId -> projectId map

    // Map parent properties
    projects.forEach((project) => {
      if (project.attributes.energyStarId) {
        projectMap.set(project.attributes.energyStarId, project.id);
      }
      // Map child properties
      project.attributes.childProperties?.forEach((childProp) => {
        if (childProp.energyStarId) {
          projectMap.set(childProp.energyStarId, project.id);
        }
      });
    });

    // Map the properties with their matching project IDs
    return properties.map((property) => ({
      ...property,
      projectId: projectMap.get(property.id),
      childProperties: (property.childProperties || []).map((child) => ({
        ...child,
        projectId: projectMap.get(child.id),
      })),
    }));
  }, [properties, projects]);

  const handleSync = async (property: EnergyStarProperty, parentId?: string) => {
    try {
      const energyStarAccountId = authUser?.relationships?.organization?.data?.energyStarAccountId;
      if (!energyStarAccountId) {
        throw new Error('Energy Star Account ID not found');
      }

      await onPropertySync(energyStarAccountId, property.id, parentId);
      setRefreshProjects((prev) => !prev);
    } catch (error) {
      console.log('Error linking Energy Star property:', error);
    }
  };

  const renderPropertyRow = (
    property: (EnergyStarProperty | EnergyStarChildProperty) & { projectId?: string },
    isChild: boolean = false,
    index: number,
    parentId?: string
  ) => {
    return (
      <tr
        key={property.id}
        className={cn({
          'bg-white/5': index % 2 === 0,
          'bg-white/10': isChild && index % 2 === 0,
          'bg-white/[0.03]': isChild && index % 2 !== 0,
        })}
      >
        <td className="p-2 text-sm">
          <div className={`flex items-center ${isChild ? 'ml-8' : ''}`}>
            {isChild && <span className="mr-2 text-muted-foreground">└─</span>}
            <span className={cn('font-medium', { 'text-muted-foreground': isChild })}>{property.name}</span>
          </div>
        </td>
        <td className="p-2 text-sm text-muted-foreground">{property.id}</td>
        <td className="p-2">
          {property.projectId ? (
            <Button
              variant="outline"
              size="sm"
              className="border-[#6E56CF] text-[#6E56CF] transition-colors hover:bg-[#6E56CF] hover:text-white"
              asChild
            >
              <Link to={`/dashboard/project/${property.projectId}/building-info`}>See in Cosmos</Link>
            </Button>
          ) : (
            <Button
              variant="outline"
              size="sm"
              className="border-[#6E56CF] text-[#6E56CF] transition-colors hover:bg-[#6E56CF] hover:text-white"
              onClick={() => handleSync(property as EnergyStarProperty, parentId)}
            >
              Sync
            </Button>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="mb-8 overflow-x-auto rounded-xl bg-white/10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
      <h2 className="mb-4 text-xl font-bold">Energy Star Properties</h2>
      <table className="w-full text-left">
        <thead>
          <tr className="border-b border-gray-700">
            <th className="p-2 text-sm font-semibold">Property Name</th>
            <th className="p-2 text-sm font-semibold">Energy Star ID</th>
            <th className="p-2 text-sm font-semibold"></th>
          </tr>
        </thead>
        <tbody>
          {matchedProperties.map((property, index) => (
            <>
              {renderPropertyRow(property, false, index)}
              {property.childProperties.map((childProperty, childIndex) =>
                renderPropertyRow(childProperty, true, index + childIndex + 0.5, property.id)
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};
