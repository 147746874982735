import { AxiosResponse } from 'axios';
import jwtAuthAxios from './auth/jwtAuth';

type User = {
  relationships: { organization: { data: { id: string } } };
  id: string;
  attributes: { token: string };
};

const authServices = {
  getCurrentUser: async () => {
    try {
      const { data } = await jwtAuthAxios.get('/v1/users/current');
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: 'Error fetching current user',
      };
    }
  },

  //loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
  signIn: async (loginCreds: { email: string; password: string }): Promise<any> => {
    const { data } = await jwtAuthAxios.post('/v1/login', loginCreds);
    return data;
  },

  signInWithGoogle: async (googleLoginCreds): Promise<any> => {
    const { data } = await jwtAuthAxios.post('/v1/login/google', googleLoginCreds);
    return data;
  },

  getUserByTempToken: async (token: string): Promise<User> => {
    const { data } = await jwtAuthAxios.get(`/v1/login/${token}`);
    return data;
  },

  forgotPassword: async (email: string): Promise<AxiosResponse> => {
    const { data } = await jwtAuthAxios.post('/v1/login/links', { email });
    return data;
  },
};

export default authServices;
