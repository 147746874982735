import type { VariantProps } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { LoaderCircleIcon } from 'lucide-react';
import { useToast } from '@shadcn/hooks/use-toast';
import { useParams, useNavigate } from 'react-router-dom';

import { Tabs, TabsList, TabsTrigger, TabsContent, toastVariants } from '@shadcn/ui';
import { extractError } from 'app/utils/appHelpers';
import projectServices from 'app/services/project-services';

import { BuildingInfo } from './tabs/BuildingInfo';
import { Documents } from './tabs/Documents';
import { Reports } from './tabs/Reports';
import { Visualizations, WeatherProps } from './tabs/Visualizations';
import EstimatedPenalties from './tabs/EstimatedPenalties';
import Benchmarks from './tabs/Benchmarks';
import { Meters } from './tabs/Meters';
import type { ProjectInfoData } from 'app/services/project-services';

export type ProjectDetailData = {
  attributes: {
    weather: WeatherProps;
  };
  id: string;
};

enum Tab {
  BuildingInfo = 'building-info',
  UtilityBills = 'utility-bills',
  Reports = 'reports',
  Visualizations = 'visualizations',
  EstimatedPenalties = 'estimated-penalties',
  Benchmarks = 'benchmarks',
  Meters = 'meters',
}

const TAB_LABELS = [
  [Tab.BuildingInfo, 'Building Info'],
  [Tab.UtilityBills, 'Utility Bills'],
  [Tab.Reports, 'Reports'],
  [Tab.Visualizations, 'Visualizations'],
  [Tab.EstimatedPenalties, 'Estimated Penalties'],
  [Tab.Benchmarks, 'Benchmarks'],
  [Tab.Meters, 'Meters'],
];

export const ProjectDetail = () => {
  const { id, page } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState<ProjectInfoData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<Tab>(() => {
    return Object.values(Tab).includes(page as Tab) ? (page as Tab) : Tab.BuildingInfo;
  });
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const { toast } = useToast();

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchProject = async () => {
      try {
        return await projectServices.getById(id);
      } catch (error) {
        console.error('Error fetching project:', error);
        return null;
      }
    };

    fetchProject().then((fetchedProject) => {
      if (isMounted) {
        setProject(fetchedProject);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [id, refreshTrigger]);

  useEffect(() => {
    if (page && Object.values(Tab).includes(page as Tab)) {
      setActiveTab(page as Tab);
    }
  }, [page]);

  const handleTabChange = (value: string) => {
    const newTab = value as Tab;
    if (Object.values(Tab).includes(newTab)) {
      setActiveTab(newTab);
      navigate(`/dashboard/project/${id}/${newTab}`);
    }
  };

  if (isLoading || !id) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  const handleSnackbar = (message: string, variant: VariantProps<typeof toastVariants>['variant'] = 'positive') => {
    toast({
      description: message,
      variant,
    });
  };

  const handleUpdateProject = async (updatedProject) => {
    try {
      const result = await projectServices.update(updatedProject);
      if (result) {
        setProject(result);
        handleSnackbar('Project updated successfully', 'positive');
        setRefreshTrigger((prev) => prev + 1);
        return { success: true, message: 'Project updated successfully', report: result };
      } else {
        throw new Error(result?.message || 'Failed to update project.');
      }
    } catch (error) {
      handleSnackbar(extractError(error) ?? 'An error occurred while updating the project', 'destructive');
      return {
        success: false,
        message: extractError(error) || 'An error occurred while updating the project',
      };
    }
  };

  return (
    <Tabs
      className="flex grow flex-col overflow-hidden"
      value={activeTab}
      onValueChange={handleTabChange}
      aria-label="project navigation"
    >
      <div className="border-b border-b-border px-4">
        <TabsList>
          {TAB_LABELS.map(([value, label]) => (
            <TabsTrigger key={value} value={value}>
              {label}
            </TabsTrigger>
          ))}
        </TabsList>
      </div>
      <TabsContent asChild value={Tab.BuildingInfo}>
        {project ? (
          <BuildingInfo project={project} onUpdateProject={handleUpdateProject} onSnackbar={handleSnackbar} />
        ) : null}
      </TabsContent>
      <TabsContent asChild value={Tab.UtilityBills}>
        {project ? <Documents projectId={id} onSnackbar={handleSnackbar} /> : null}
      </TabsContent>
      <TabsContent asChild value={Tab.Reports}>
        {project ? <Reports projectId={id} onSnackbar={handleSnackbar} /> : null}
      </TabsContent>
      <TabsContent asChild value={Tab.Visualizations}>
        {project ? <Visualizations projectId={id} onSnackbar={handleSnackbar} /> : null}
      </TabsContent>
      <TabsContent asChild value={Tab.EstimatedPenalties}>
        {project ? <EstimatedPenalties projectId={id} /> : null}
      </TabsContent>
      <TabsContent asChild value={Tab.Benchmarks}>
        {project ? <Benchmarks projectId={id} /> : null}
      </TabsContent>
      <TabsContent asChild value={Tab.Meters}>
        {project ? <Meters projectId={id} onSnackbar={handleSnackbar} /> : null}
      </TabsContent>
    </Tabs>
  );
};
