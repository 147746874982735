import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui/card';
import { ProjectsTable } from 'app/components/projects/ProjectsTable';
import { ReviewTables } from 'app/components/projects/ReviewTables';
import { EnergyStarPropertiesTable } from 'app/components/energy-star/EnergyStarPropertiesTable';
import type { Task } from 'app/types/task';
import type { ProjectInfoData } from 'app/services/project-services';
import { Dispatch, SetStateAction } from 'react';

interface MainContentProps {
  tasks: Task[];
  projects: ProjectInfoData[];
  showProjectsTable: boolean;
  showEnergyStarProperties: boolean;
  propertyScores: Record<string, number>;
  reviewingProject: { projectId: string; isVisible: boolean } | null;
  energyStarProperties: any[];
  syncedProperties: Set<string>;
  setRefreshProjects: Dispatch<SetStateAction<boolean>>;
  onTaskClick: (task: Task) => void;
  onReviewClick: (project: ProjectInfoData) => void;
  onSubmitScore: (propertyName: string) => void;
  onPropertySync: (customerId: string, propertyId: string) => void;
  onReviewClose: () => void;
  onSnackbar: (message: string, variant: 'default' | 'destructive' | 'positive') => void;
}

export const MainContent = ({
  tasks,
  projects,
  showProjectsTable,
  showEnergyStarProperties,
  propertyScores,
  reviewingProject,
  energyStarProperties,
  syncedProperties,
  setRefreshProjects,
  onTaskClick,
  onReviewClick,
  onSubmitScore,
  onPropertySync,
  onReviewClose,
  onSnackbar,
}: MainContentProps) => {
  const handleProjectSync = (projectId: string) => {
    onPropertySync(projectId, projectId);
  };

  return (
    <div className="flex h-full flex-1 flex-col overflow-hidden">
      <div className="flex-1 overflow-y-auto">
        <div className="space-y-6 p-6">
          <div className="mb-6">
            <h2 className="text-xl">{tasks.length > 0 ? 'Action Required' : "You're all caught up!"}</h2>
          </div>

          <div className="space-y-6">
            {tasks.length > 0 && (
              <div className="grid gap-4">
                {tasks.map((task) => (
                  <Card
                    key={task.id}
                    onClick={() => onTaskClick(task)}
                    className="cursor-pointer transition-colors hover:bg-accent"
                  >
                    <CardHeader>
                      <CardTitle>{task.title}</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="flex items-start gap-4">
                        <div className="flex flex-col">
                          <span className="font-semibold">{task.subtitle}</span>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )}

            {reviewingProject && <ReviewTables projectId={reviewingProject.projectId} onClose={onReviewClose} />}

            {showProjectsTable && (
              <Card>
                <CardHeader>
                  <CardTitle>Properties for Benchmark Report</CardTitle>
                </CardHeader>
                <CardContent>
                  <ProjectsTable
                    projects={projects}
                    propertyScores={propertyScores}
                    onReviewClick={onReviewClick}
                    onSubmitScore={onSubmitScore}
                    onSync={handleProjectSync}
                  />
                </CardContent>
              </Card>
            )}

            {showEnergyStarProperties && (
              <Card>
                <CardHeader>
                  <CardTitle>Energy Star Properties</CardTitle>
                </CardHeader>
                <CardContent>
                  <EnergyStarPropertiesTable
                    properties={energyStarProperties}
                    syncedProperties={syncedProperties}
                    projects={projects}
                    setRefreshProjects={setRefreshProjects}
                    onPropertySync={onPropertySync}
                    onSnackbar={onSnackbar}
                  />
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
