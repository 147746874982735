type PromptAction = {
  text: string;
  aiResponse: string;
  action?: () => Promise<void>;
  showTable?: boolean;
  showEnergyStarProperties?: boolean;
};

export const defaultPrompts: PromptAction[] = [
  {
    text: 'Review benchmarks',
    aiResponse:
      "Great, I can help with that. I see you're already connected to Energy Star Portfolio Manager. Let's pull up your properties.",
    showTable: true,
  },
  {
    text: 'Sync with Energy Star Portfolio Manager',
    aiResponse: "I'll help you sync your properties with Energy Star Portfolio Manager.",
    showEnergyStarProperties: true,
  },
];
