import { VariantProps } from 'class-variance-authority';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { ScrollArea, toastVariants, Tabs, TabsList, TabsTrigger, TabsContent } from '@shadcn/ui';
import documentServices from 'app/services/document-services';
import { extractError } from 'app/utils/appHelpers';
import { LoaderCircleIcon, BoltIcon, FlameIcon, DropletIcon, FuelIcon, ListIcon, FileIcon } from 'lucide-react';
import { ELECTRIC, GAS, STEAM, OIL, WATER } from 'app/utils/constants/utilityServices';

import { FileWithMetadata } from './document-steps/DocumentUpload';
import { DocumentsTable } from './components/DocumentsTable';
import { DataAlert } from 'app/components/DataAlert';
import { AddDocumentButton } from './components/AddDocumentButton';
import { NoDataFound } from 'app/components/project/NoDataFound';

export interface Document {
  _id: string;
  attributes: {
    fileName: string;
    documentType: string;
    status: string;
    createdAt: string;
    fileSize: number;
    detectedData?: Record<string, any>;
  };
}

interface DocumentPageProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

export const Documents = forwardRef<HTMLDivElement, DocumentPageProps>(({ projectId, onSnackbar }, ref) => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const fetchDocuments = useCallback(async () => {
    try {
      return await documentServices.getByProjectId(projectId);
    } catch (error) {
      onSnackbar('Failed to fetch documents', 'destructive');
    }
  }, [projectId, onSnackbar]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    fetchDocuments()
      .then((docs) => {
        if (isMounted && docs) {
          setDocuments(docs);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [fetchDocuments, refreshTrigger]);

  const handleDocumentDelete = async (fileId: string) => {
    setIsLoading(true);

    const msg = { success: 'Document(s) deleted successfully.', fail: 'Failed to delete document(s).' };
    try {
      const result = await documentServices.delete(fileId);
      if (result && result.status === 204) {
        onSnackbar(msg.success, 'destructive');
        setRefreshTrigger((prev) => prev + 1);
        return { success: true };
      } else {
        throw new Error(msg.fail);
      }
    } catch (error) {
      onSnackbar(extractError(error) || msg.fail, 'destructive');
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateDocument = async (files: FileWithMetadata[], billBreaks: Record<string, number[]>) => {
    setIsLoading(true);

    try {
      const results = await documentServices.create({
        files,
        projectId,
        billBreaks,
      });

      onSnackbar('Documents created successfully.', 'positive');
      setRefreshTrigger((prev) => prev + 1);
      setIsUploadDialogOpen(false);
      return results;
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to create documents.', 'destructive');
    } finally {
      setIsLoading(false);
    }
  };

  interface DuplicateData {
    type: 'billing_period' | 'amount_due';
    documents: {
      id: string;
      fileName: string;
      value: string;
    }[];
  }

  const findDuplicates = useCallback((docs: Document[]): DuplicateData[] => {
    const duplicates: DuplicateData[] = [];
    const billingPeriodMap = new Map<string, typeof docs>();
    const amountDueMap = new Map<string, typeof docs>();

    docs.forEach((doc) => {
      if (!doc.attributes.detectedData?.billing_summary) return;

      // Check billing period
      const startDate = doc.attributes.detectedData.billing_summary.billing_start_date;
      const endDate = doc.attributes.detectedData.billing_summary.billing_end_date;

      if (startDate && endDate) {
        const periodKey = `${startDate}_${endDate}`;
        const existing = billingPeriodMap.get(periodKey) || [];
        billingPeriodMap.set(periodKey, [...existing, doc]);
      }

      // Check amount due
      const amountDue = doc.attributes.detectedData.billing_summary.total_this_billing_period;
      if (amountDue) {
        const amountKey = amountDue.toString();
        const existing = amountDueMap.get(amountKey) || [];
        amountDueMap.set(amountKey, [...existing, doc]);
      }
    });

    // Add billing period duplicates
    billingPeriodMap.forEach((docs, period) => {
      if (docs.length > 1) {
        const [start, end] = period.split('_');
        duplicates.push({
          type: 'billing_period',
          documents: docs.map((doc) => ({
            id: doc._id,
            fileName: doc.attributes.fileName,
            value: `${new Date(start).toLocaleDateString()} - ${new Date(end).toLocaleDateString()}`,
          })),
        });
      }
    });

    // Add amount due duplicates
    amountDueMap.forEach((docs, amount) => {
      if (docs.length > 1) {
        duplicates.push({
          type: 'amount_due',
          documents: docs.map((doc) => ({
            id: doc._id,
            fileName: doc.attributes.fileName,
            value: `$${parseFloat(amount).toFixed(2)}`,
          })),
        });
      }
    });

    return duplicates;
  }, []);

  const getServiceDocuments = (docs: Document[], service: string) => {
    return docs.filter(
      (doc) => doc.attributes.detectedData && Object.keys(doc.attributes.detectedData).includes(service.toLowerCase())
    );
  };

  const services = [ELECTRIC, GAS, STEAM, OIL, WATER];
  const allServices = ['All', ...services];

  if (isLoading) {
    return (
      <div className="flex h-full grow items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-2">
        <h2 className="font-bold">Utility Bills</h2>
        <AddDocumentButton
          onDocumentCreate={handleCreateDocument}
          variant={documents.length ? 'default' : 'secondary'}
        />
      </div>
      <ScrollArea orientation="both" className="p-4">
        {documents.length === 0 ? (
          <NoDataFound
            message="No utility bills found"
            submessage="Upload utility bills to get started"
            icon={<FileIcon className="h-8 w-8 text-muted-foreground" />}
          />
        ) : (
          <Tabs defaultValue="All" className="w-full">
            <TabsList className="mb-8 flex h-12 w-full justify-start gap-2 rounded-lg border border-border/20 bg-muted p-1 shadow-[inset_0_2px_4px_rgba(0,0,0,0.4),0_4px_6px_-1px_rgba(0,0,0,0.3)] after:absolute after:bottom-[-8px] after:left-0 after:h-[8px] after:w-full after:bg-gradient-to-b after:from-black/20 after:to-transparent">
              {allServices.map((serviceType) => (
                <TabsTrigger
                  key={serviceType}
                  value={serviceType}
                  className="flex-1 items-center justify-center rounded-md border border-border/10 bg-background/40 text-muted-foreground transition-all hover:bg-background/60 data-[state=active]:border-primary/30 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:shadow-[0_2px_10px_rgba(124,58,237,0.5)]"
                >
                  {serviceType === 'All' && <ListIcon className="mr-2 h-4 w-4 text-gray-400" />}
                  {serviceType === ELECTRIC && <BoltIcon className="mr-2 h-4 w-4 text-yellow-400" />}
                  {serviceType === GAS && <FlameIcon className="mr-2 h-4 w-4 text-orange-400" />}
                  {(serviceType === STEAM || serviceType === WATER) && (
                    <DropletIcon className="mr-2 h-4 w-4 text-blue-400" />
                  )}
                  {serviceType === OIL && <FuelIcon className="mr-2 h-4 w-4 text-green-400" />}
                  <span className="font-medium">{serviceType.charAt(0).toUpperCase() + serviceType.slice(1)}</span>
                </TabsTrigger>
              ))}
            </TabsList>

            <TabsContent value="All">
              <DataAlert variant="duplicate" data={findDuplicates(documents)} />
              <DocumentsTable documents={documents} onDelete={handleDocumentDelete} selectedService="All" />
            </TabsContent>

            {services.map((serviceType) => (
              <TabsContent key={serviceType} value={serviceType}>
                <DataAlert variant="duplicate" data={findDuplicates(getServiceDocuments(documents, serviceType))} />
                <DocumentsTable
                  documents={getServiceDocuments(documents, serviceType)}
                  onDelete={handleDocumentDelete}
                  selectedService={serviceType}
                />
              </TabsContent>
            ))}
          </Tabs>
        )}
      </ScrollArea>
    </div>
  );
});
