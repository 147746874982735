import { useState, useEffect, forwardRef, useCallback } from 'react';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  ScrollArea,
  toastVariants,
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import { VariantProps } from 'class-variance-authority';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsToolTip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from 'recharts';
import {
  SunIcon,
  CloudRainIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  RefreshCwIcon,
  Loader2Icon,
  XIcon,
  BarChartIcon,
  LineChartIcon,
  BoltIcon,
  FlameIcon,
  DropletIcon,
  FuelIcon,
} from 'lucide-react';
import projectServices, { WeatherParams } from 'app/services/project-services';
import { extractError } from 'app/utils/appHelpers';
import { ELECTRIC, GAS, STEAM, OIL, WATER } from 'app/utils/constants/utilityServices';
import { DataAlert } from 'app/components/DataAlert';
import type { MissingMonth } from 'app/components/DataAlert';
import { NoDataFound } from 'app/components/project/NoDataFound';
import { BaseTemperatureCard } from 'app/components/cards/BaseTemperatureCard';

const SERVICE_METRICS = {
  electric: 'total_usage_kwh',
  steam: 'total_steam_use_mlb',
  gas: 'total_gas_use_therms',
  oil: 'units',
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;

const fetchWithRetry = async (
  fetchFn: () => Promise<any>,
  attempt = 0,
  maxRetries = MAX_RETRIES,
  delay = RETRY_DELAY
) => {
  try {
    return await fetchFn();
  } catch (error) {
    if (attempt >= maxRetries) {
      throw error;
    }
    await new Promise((resolve) => setTimeout(resolve, delay * Math.pow(2, attempt)));
    return fetchWithRetry(fetchFn, attempt + 1, maxRetries, delay);
  }
};

interface DaysProps {
  temp: number;
  tempmax: number;
}

export interface WeatherProps {
  address: string;
  days: DaysProps[];
}

interface VisualizationsProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

interface InsightCardProps {
  title: string;
  value: string | number;
  unit?: string;
}

interface DocumentData {
  months: Array<{
    billing_end_date: string;
    billing_start_date: string;
    billing_summary_date: string;
  }>;
  startDate: string;
  endDate: string;
  stats: {
    loadFactor: number;
    avgLoad: number;
    totalUsage: number;
    totalCost: number;
  };
}

interface Documents {
  [key: string]: DocumentData;
}

interface MainChartProps {
  data: any[];
  metric: string;
  weatherData?: WeatherProps;
  units: string;
}

const InsightCard = ({ title, value, unit }: InsightCardProps) => (
  <Card className="rounded-xl border-border bg-white/10 shadow-lg transition duration-300 hover:border-border/5 hover:bg-white/5">
    <CardHeader>
      <CardTitle className="">{title}</CardTitle>
    </CardHeader>
    <CardContent>
      {value + ' '} {unit ? <span className="text-muted-foreground">{unit}</span> : null}
    </CardContent>
  </Card>
);

const formatNumber = (num, decimalPlaces = 0) => {
  if (num === null || num === undefined || isNaN(num)) {
    return '0';
  }

  const parts = Number(num).toFixed(decimalPlaces).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const formatDateForInput = (dateString: string) => {
  if (!dateString) return '';
  const [month, day, year] = dateString.split('-');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

const MainChart = ({ data, metric, weatherData, units }: MainChartProps) => {
  if (!data || data.length === 0) {
    return (
      <NoDataFound
        message="No usage data available"
        submessage="There is no usage data to display for this time period"
        icon={<LineChartIcon className="h-8 w-8 text-muted-foreground" />}
      />
    );
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="monthYear" />
        <YAxis
          yAxisId="left"
          orientation="left"
          label={{ value: units, angle: -90, position: 'insideLeft', fill: 'hsl(var(--foreground))' }}
          tickFormatter={(value) => formatNumber(value)}
        />
        {weatherData ? (
          <YAxis
            label={{
              value: 'Degrees (°F)',
              angle: -90,
              position: 'insideRight',
              fill: 'hsl(var(--foreground))',
            }}
            tickFormatter={(value) => formatNumber(value)}
            yAxisId="right"
            orientation="right"
          />
        ) : null}
        <RechartsToolTip
          contentStyle={{ backgroundColor: 'hsl(var(--background))', border: 'none', borderRadius: '4px' }}
          labelStyle={{ color: 'hsl(var(--foreground))' }}
          itemStyle={{ color: 'hsl(var(--foreground))' }}
          formatter={(value) => formatNumber(value)}
        />
        <Legend />
        <Line
          yAxisId="left"
          unit={` ${units}`}
          name={units}
          type="linear"
          dataKey={metric}
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        {weatherData ? (
          <Line unit=" °F" name="Temperature" yAxisId="right" type="linear" dataKey="temperature" stroke="#82ca9d" />
        ) : null}
      </LineChart>
    </ResponsiveContainer>
  );
};

interface CollapsibleChartProps {
  title: string;
  children: React.ReactNode;
}

const CollapsibleChart = ({ title, children }: CollapsibleChartProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="mb-8 rounded-lg bg-white/10 shadow-lg transition-all duration-300">
      <div
        className="flex cursor-pointer items-center justify-between p-6 hover:bg-white/5"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h3 className="text-2xl font-semibold text-primary-foreground">{title}</h3>
        <ChevronDownIcon className={cn('h-6 w-6 transition-transform duration-200', isCollapsed ? 'rotate-180' : '')} />
      </div>
      <div className={cn('overflow-hidden transition-all duration-300', isCollapsed ? 'h-0' : 'h-auto')}>
        <div className="p-6 pt-0">{children}</div>
      </div>
    </div>
  );
};

export const Visualizations = forwardRef<HTMLDivElement, VisualizationsProps>(({ projectId, onSnackbar }, ref) => {
  const [documents, setDocuments] = useState<Documents>({});
  const [isLoading, setIsLoading] = useState(true);
  const [visSections, setVisSections] = useState<{ [key: string]: boolean }>({});
  const [isWeatherOpen, setIsWeatherOpen] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isWeatherLoading, setIsWeatherLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [weather, setWeather] = useState({} as WeatherProps);
  const [degreeData, setDegreeData] = useState({});
  const [baseloads, setBaseloads] = useState({});
  const [baseTemperature, setBaseTemperature] = useState(65);
  const [isUpdatingDegreeData, setIsUpdatingDegreeData] = useState(false);

  const fetchData = useCallback(
    async (baseTemp: number) => {
      setIsUpdatingDegreeData(true);

      try {
        const insightsParams = { id: projectId, data: { baseTemp, degreeDataTypes: ['hdd', 'cdd'] } };
        const regressionResult = await fetchWithRetry(async () => projectServices.fetchProjectInsights(insightsParams));

        const { degreeData, baseloads, formattedDocuments, services, weather, zipCode } = regressionResult;
        setDocuments(formattedDocuments);
        setVisSections(services.reduce((acc, key) => ({ ...acc, [key]: true }), {}));
        setWeather(weather);
        setBaseloads(baseloads);
        setDegreeData(degreeData);
        setZipCode(zipCode || '');

        if (formattedDocuments && Object.keys(formattedDocuments).length > 0) {
          const startDate = Object.values(formattedDocuments as DocumentData).reduce(
            (earliest, doc) => {
              const { startDate } = doc;
              return startDate !== '' && startDate < earliest ? startDate : earliest;
            },
            Object.values(formattedDocuments as DocumentData)[0].startDate
          );

          const endDate = Object.values(formattedDocuments as DocumentData).reduce(
            (latest, doc) => {
              const { endDate } = doc;
              return endDate !== '' && endDate > latest ? endDate : latest;
            },
            Object.values(formattedDocuments as DocumentData)[0].endDate
          );

          setStartDate(formatDateForInput(startDate));
          setEndDate(formatDateForInput(endDate));
        }
      } catch (error) {
        onSnackbar(extractError(error) || 'Failed to update degree day data.', 'destructive');
      } finally {
        setIsUpdatingDegreeData(false);
        setIsLoading(false);
      }
    },
    [projectId, onSnackbar]
  );

  useEffect(() => {
    fetchData(baseTemperature);
  }, [baseTemperature]);

  const handleSliderChange = (value: number[]) => {
    const newValue = value[0];
    setBaseTemperature(newValue);
  };

  if (isLoading) {
    return (
      <div className="flex h-[100%] items-center justify-center py-8">
        <Loader2Icon className={cn('h-20 w-20 animate-spin text-primary')} />
      </div>
    );
  }

  if (!visSections || Object.keys(visSections).length === 0) {
    return (
      <div className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
        <NoDataFound
          message="No utility data available"
          submessage="Please upload utility documents to view visualizations"
          icon={<LineChartIcon className="h-8 w-8 text-muted-foreground" />}
        />
      </div>
    );
  }

  const renderLoadMetric = (serviceType: string) => {
    switch (serviceType) {
      case ELECTRIC:
        return 'kWh';
      case GAS:
        return 'therms';
      case STEAM:
        return 'Mlbs';
      case OIL:
        return 'gallons';
      default:
        return 'Unknown metric';
    }
  };

  const formatValue = (value: number | undefined, isPercentage: boolean = false) => {
    if (!value) return '0';

    if (isPercentage) {
      return `${(value * 100).toFixed(2)}%`;
    }
    return value.toLocaleString();
  };

  const handleAddWeather = async () => {
    setIsWeatherLoading(true);
    try {
      const params: WeatherParams = {
        id: projectId,
        data: { zipCode, startDate, endDate },
      };

      const savedProject = await projectServices.addWeather(params);
      if (savedProject) {
        onSnackbar('Weather data added successfully.', 'positive');
        setWeather(savedProject.weather);
        setIsEditing(false);
        setIsWeatherOpen(false);
      }
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to add weather data.', 'destructive');
    } finally {
      setIsWeatherLoading(false);
    }
  };

  const toggleWeather = () => {
    setIsWeatherOpen(!isWeatherOpen);
    setIsEditing(false);
  };

  const startEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
  };

  const renderWeatherIcon = () => {
    let hasDocuments = false;
    Object.values(documents).forEach((entry) => {
      if (entry?.months.length > 0) {
        hasDocuments = true;
      }
    });

    const buttonClass = hasDocuments ? '' : 'opacity-50';
    const tooltipContent = hasDocuments ? 'Add or edit weather data' : 'Add documents to enable weather data';
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="inline-block">
            <Button onClick={toggleWeather} disabled={!hasDocuments} className={buttonClass}>
              {isWeatherOpen ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              {weather && Object.keys(weather).length > 0 ? (
                <SunIcon className="ml-2 h-6 w-6 text-yellow-400" />
              ) : (
                <CloudRainIcon className="ml-2 h-6 w-6" />
              )}
            </Button>
          </span>
        </TooltipTrigger>
        <TooltipContent>{tooltipContent}</TooltipContent>
      </Tooltip>
    );
  };

  const getTrendData = (serviceType: string, degreeDayType: string) => {
    if (!documents[serviceType]) return { trendData: [], missingMonths: [] };

    const hasBaseloads = !!baseloads?.[serviceType];
    const hasWeather = !!weather;
    const hasDegreeData = !!degreeData;

    const trendData = documents[serviceType].months.map((month) => {
      const isOil = serviceType === 'oil';
      const [m, d, y] = isOil ? month.billing_summary_date.split('-') : month.billing_start_date.split('-');
      const monthDayYear = `${m}-${d}-${y}`;
      const monthYear = `${m}-${y}`;
      const result: any = {
        monthYear: isOil ? monthDayYear : monthYear,
        totalUsage: month[SERVICE_METRICS[serviceType]],
      };

      if (serviceType === ELECTRIC) {
        result.peakDemandKw = month['total_demand_kw'];
      }

      if (hasWeather && weather[monthYear]) {
        result.temperature = weather[monthYear]['avg'];
      }

      if (hasBaseloads) {
        result.baseload = baseloads[serviceType].baseload;
      }

      if (hasDegreeData && degreeData[monthYear]) {
        result.degreeDay = degreeData[monthYear][degreeDayType];
        result.degreeDayMetric = degreeDayType;
      }

      return result;
    });
    const missingMonths = findMissingMonths(documents[serviceType].months, serviceType, trendData);
    return { trendData, missingMonths };
  };

  const findMissingMonths = (months: any[], serviceType: string, trendData?: any[]) => {
    const missingMonths: MissingMonth[] = [];
    const monthsInData = new Set();

    // Get all unique years from the data
    const years = new Set(
      months.map((month) => {
        const [m, , y] =
          serviceType === 'oil' ? month.billing_summary_date.split('-') : month.billing_start_date.split('-');
        return y;
      })
    );

    // Add all existing month-year combinations to a Set
    months.forEach((month) => {
      const [m, , y] =
        serviceType === 'oil' ? month.billing_summary_date.split('-') : month.billing_start_date.split('-');
      monthsInData.add(`${m}-${y}`);
    });

    // Check for months with missing or zero values in trendData
    if (trendData) {
      trendData.forEach((data) => {
        const monthYear = data.monthYear;

        // Check for missing total usage
        if (!data.totalUsage || data.totalUsage === 0) {
          if (!missingMonths.some((m) => m.monthYear === monthYear)) {
            missingMonths.push({
              monthYear,
              type: 'missing_total_usage',
            });
          }
        }

        // Check for missing peak demand (electric only)
        if (serviceType === ELECTRIC && (!data.peakDemandKw || data.peakDemandKw === 0)) {
          if (!missingMonths.some((m) => m.monthYear === monthYear)) {
            missingMonths.push({
              monthYear,
              type: 'missing_peak_demand',
            });
          }
        }
      });
    }

    // Check each month of each year for completely missing data
    years.forEach((year) => {
      for (let month = 1; month <= 12; month++) {
        const monthStr = month.toString().padStart(2, '0');
        const monthYear = `${monthStr}-${year}`;
        if (!monthsInData.has(monthYear) && !missingMonths.some((m) => m.monthYear === monthYear)) {
          missingMonths.push({
            monthYear,
            type: 'missing_month',
          });
        }
      }
    });

    return missingMonths.sort((a, b) => a.monthYear.localeCompare(b.monthYear));
  };

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-2">
        <h2 className="font-bold">Energy Usage Dashboard</h2>
        <div className="flex items-center gap-6">
          <span className="mr-2 text-muted-foreground">Weather Data:</span>
          {renderWeatherIcon()}
        </div>
      </div>

      {isWeatherOpen && Object.keys(documents).length > 0 && (
        <Card className="mx-4 mb-8 animate-fade-up-in rounded-xl border-border">
          <CardHeader>
            <CardTitle>{weather && Object.keys(weather).length > 0 ? 'Weather Data' : 'Add Weather Data'}</CardTitle>
            <CardDescription>
              {weather && Object.keys(weather).length > 0
                ? 'Current weather data for this location.'
                : 'Click to submit a request for weather data in this date range.'}
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col gap-4">
            <InputWrapper
              type="text"
              label="ZIP Code"
              placeholder="ZIP Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              disabled={!isEditing && weather && Object.keys(weather).length > 0}
            />
            <InputWrapper
              type="date"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={!isEditing && weather && Object.keys(weather).length > 0}
            />
            <InputWrapper
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled={!isEditing && weather && Object.keys(weather).length > 0}
            />
            {weather && Object.keys(weather).length > 0 ? (
              isEditing ? (
                <div className="flex justify-end gap-2">
                  <Button onClick={cancelEditing} variant="outline">
                    <XIcon className="mr-2 h-4 w-4" />
                    Cancel
                  </Button>
                  <Button onClick={handleAddWeather} disabled={isWeatherLoading}>
                    {isWeatherLoading ? <Loader2Icon size="sm" className="mr-2" /> : null}
                    Submit
                  </Button>
                </div>
              ) : (
                <Button onClick={startEditing} className="self-end">
                  <RefreshCwIcon className="mr-2 h-4 w-4" />
                  Edit weather
                </Button>
              )
            ) : (
              <Button onClick={handleAddWeather} disabled={isWeatherLoading} className="self-end">
                {isWeatherLoading ? <Loader2Icon size="sm" className="mr-2" /> : null}
                Submit
              </Button>
            )}
          </CardContent>
        </Card>
      )}

      <ScrollArea orientation="vertical" className="grow p-4">
        <Tabs defaultValue={Object.keys(visSections)[0]} className="w-full">
          <TabsList className="mb-8 flex h-12 w-full justify-start gap-2 rounded-lg border border-border/20 bg-muted p-1 shadow-[inset_0_2px_4px_rgba(0,0,0,0.4),0_4px_6px_-1px_rgba(0,0,0,0.3)] after:absolute after:bottom-[-8px] after:left-0 after:h-[8px] after:w-full after:bg-gradient-to-b after:from-black/20 after:to-transparent">
            {Object.keys(visSections).map((serviceType) => (
              <TabsTrigger
                key={serviceType}
                value={serviceType}
                className="flex-1 items-center justify-center rounded-md border border-border/10 bg-background/40 text-muted-foreground transition-all hover:bg-background/60 data-[state=active]:border-primary/30 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:shadow-[0_2px_10px_rgba(124,58,237,0.5)]"
              >
                {serviceType === ELECTRIC && <BoltIcon className="mr-2 h-4 w-4 text-yellow-400" />}
                {serviceType === GAS && <FlameIcon className="mr-2 h-4 w-4 text-orange-400" />}
                {(serviceType === STEAM || serviceType === WATER) && (
                  <DropletIcon className="mr-2 h-4 w-4 text-blue-400" />
                )}
                {serviceType === OIL && <FuelIcon className="mr-2 h-4 w-4 text-green-400" />}
                <span className="font-medium">{serviceType.charAt(0).toUpperCase() + serviceType.slice(1)}</span>
              </TabsTrigger>
            ))}
          </TabsList>

          {Object.keys(visSections).map((serviceType) => (
            <TabsContent key={serviceType} value={serviceType}>
              <div>
                <DataAlert
                  variant="missing"
                  data={{
                    [serviceType]: getTrendData(serviceType, serviceType === ELECTRIC ? 'cdd' : 'hdd').missingMonths,
                  }}
                />
                <div className="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
                  {serviceType === ELECTRIC ? (
                    <InsightCard
                      title="Load Factor"
                      value={formatValue(documents[serviceType]?.stats?.loadFactor, true)}
                    />
                  ) : null}
                  <InsightCard
                    title="Average Load"
                    value={formatValue(documents[serviceType]?.stats?.avgLoad)}
                    unit={renderLoadMetric(serviceType)}
                  />
                  <InsightCard
                    title="Total Usage"
                    value={formatValue(documents[serviceType]?.stats?.totalUsage)}
                    unit={renderLoadMetric(serviceType)}
                  />
                  <InsightCard
                    title="Total Cost"
                    value={`$${formatValue(documents[serviceType]?.stats?.totalCost, false)}`}
                  />
                  {baseloads[serviceType] ? (
                    <InsightCard
                      title={`${serviceType === ELECTRIC ? 'Cooling' : 'Heating'} baseload`}
                      value={formatValue(baseloads[serviceType].baseload)}
                      unit={renderLoadMetric(serviceType)}
                    />
                  ) : null}
                </div>

                <CollapsibleChart title="Usage Trend">
                  {getTrendData(serviceType, serviceType === ELECTRIC ? 'cdd' : 'hdd').trendData.length > 0 ? (
                    <div className="h-[400px] w-full">
                      <MainChart
                        data={getTrendData(serviceType, serviceType === ELECTRIC ? 'cdd' : 'hdd').trendData}
                        metric="totalUsage"
                        weatherData={weather}
                        units={renderLoadMetric(serviceType)}
                      />
                    </div>
                  ) : (
                    <NoDataFound
                      message="No usage trend data available"
                      submessage="There is no usage trend data to display for this time period"
                      icon={<LineChartIcon className="h-8 w-8 text-muted-foreground" />}
                    />
                  )}
                </CollapsibleChart>

                <CollapsibleChart title={`Usage vs. Degree Days (Base Temp: ${baseTemperature}°F)`}>
                  <BaseTemperatureCard
                    baseTemperature={baseTemperature}
                    onValueChange={handleSliderChange}
                    isUpdating={isUpdatingDegreeData}
                  />

                  {getTrendData(serviceType, serviceType === ELECTRIC ? 'cdd' : 'hdd').trendData.length > 0 ? (
                    <div className="h-[500px] w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                          data={getTrendData(serviceType, serviceType === ELECTRIC ? 'cdd' : 'hdd').trendData}
                        >
                          <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                          <XAxis dataKey="monthYear" tick={{ fill: '#E0E0E0' }} />
                          <YAxis
                            yAxisId="left"
                            label={{
                              value: serviceType === ELECTRIC ? 'CDD' : 'HDD',
                              angle: -90,
                              position: 'insideLeft',
                              fill: '#E0E0E0',
                            }}
                            tick={{ fill: '#E0E0E0' }}
                          />
                          <YAxis
                            yAxisId="right"
                            orientation="right"
                            tickFormatter={(value) => formatNumber(value)}
                            label={{
                              value: renderLoadMetric(serviceType),
                              angle: -90,
                              position: 'insideRight',
                              fill: '#E0E0E0',
                            }}
                            tick={{ fill: '#E0E0E0' }}
                          />
                          <RechartsToolTip
                            contentStyle={{ backgroundColor: '#2D2D2D', border: 'none', borderRadius: '4px' }}
                            labelStyle={{ color: '#E0E0E0' }}
                            itemStyle={{ color: '#E0E0E0' }}
                            formatter={(value) => formatNumber(value)}
                          />
                          <Legend wrapperStyle={{ color: '#E0E0E0' }} />
                          <Bar
                            yAxisId="left"
                            dataKey="degreeDay"
                            fill="#7352C7"
                            name={serviceType === ELECTRIC ? 'CDD' : 'HDD'}
                          />
                          <Line
                            yAxisId="right"
                            type="linear"
                            dataKey="totalUsage"
                            stroke="#ff7300"
                            name={renderLoadMetric(serviceType)}
                            dot={{ r: 4 }}
                            activeDot={{ r: 6 }}
                          />
                          <Line
                            yAxisId="right"
                            type="linear"
                            dataKey="baseload"
                            stroke="#E0E0E0"
                            name={`Baseload (${renderLoadMetric(serviceType)})`}
                            dot={{ r: 4 }}
                            activeDot={{ r: 6 }}
                          />
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <NoDataFound
                      message="No degree day data available"
                      submessage="There is no degree day data to display for this time period"
                      icon={<BarChartIcon className="h-8 w-8 text-muted-foreground" />}
                    />
                  )}
                </CollapsibleChart>
                {serviceType === ELECTRIC && (
                  <CollapsibleChart title="Peak Demand">
                    {getTrendData(serviceType, 'cdd').trendData.length > 0 ? (
                      <div className="h-[500px] w-full">
                        <ResponsiveContainer width="100%" height="100%">
                          <ComposedChart
                            data={getTrendData(serviceType, 'cdd').trendData}
                            margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                            <XAxis
                              dataKey="monthYear"
                              tick={{ fill: '#E0E0E0' }}
                              label={{
                                value: 'Billing Period',
                                position: 'insideBottom',
                                offset: -40,
                                fill: '#E0E0E0',
                              }}
                            />
                            <YAxis
                              yAxisId="left"
                              label={{
                                value: 'kW',
                                angle: -90,
                                position: 'insideLeft',
                                fill: '#E0E0E0',
                              }}
                              tick={{ fill: '#E0E0E0' }}
                            />
                            {weather ? (
                              <YAxis
                                label={{
                                  value: 'Degrees (°F)',
                                  angle: -90,
                                  position: 'insideRight',
                                  fill: 'hsl(var(--foreground))',
                                }}
                                tickFormatter={(value) => formatNumber(value)}
                                yAxisId="right"
                                orientation="right"
                              />
                            ) : null}
                            <RechartsToolTip
                              contentStyle={{ backgroundColor: '#2D2D2D', border: 'none', borderRadius: '4px' }}
                              labelStyle={{ color: '#E0E0E0' }}
                              itemStyle={{ color: '#E0E0E0' }}
                              formatter={(value) => formatNumber(value)}
                            />
                            <Legend wrapperStyle={{ color: '#E0E0E0' }} />
                            <Bar yAxisId="left" dataKey="peakDemandKw" fill="#7352C7" name="Peak demand kW" />
                            {weather ? (
                              <Line
                                unit=" °F"
                                name="Temperature"
                                yAxisId="right"
                                type="linear"
                                dataKey="temperature"
                                stroke="#ff7300"
                              />
                            ) : null}
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <NoDataFound
                        message="No peak demand data available"
                        submessage="There is no peak demand data to display for this time period"
                        icon={<BarChartIcon className="h-8 w-8 text-muted-foreground" />}
                      />
                    )}
                  </CollapsibleChart>
                )}
              </div>
            </TabsContent>
          ))}
        </Tabs>
      </ScrollArea>
    </div>
  );
});
