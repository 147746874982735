import jwtAuthAxios from './auth/jwtAuth';

const reportServices = {
  create: async (params) => {
    const { data } = await jwtAuthAxios.post('/v1/reports', params);
    return data;
  },

  getByProjectId: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/reports/project/${id}`);
    return data;
  },

  getDownloadLink: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/reports/download/${id}`);
    return data;
  },

  delete: async (id) => {
    const data = await jwtAuthAxios.delete(`/v1/reports/${id}`);
    return data;
  },
};

export default reportServices;
