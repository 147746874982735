import { AppLink } from 'app/reusable/Link';

export const Footer = () => {
  return (
    <footer className="flex justify-between border-t-2 border-t-border bg-card px-6 py-4">
      <p className="text-primary-foreground">Cosmos Technologies © 2024</p>
      <div className="flex flex-col gap-2 text-sm text-muted-foreground no-underline">
        <AppLink className="text-inherit" to="/privacy">
          Privacy Policy
        </AppLink>
        <AppLink className="text-inherit" to="/terms-of-service">
          Terms of Service
        </AppLink>
      </div>
    </footer>
  );
};
