import { forwardRef, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, ScrollArea } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Building2, TrendingDown, DollarSign, AlertTriangle, LucideIcon, Loader2 } from 'lucide-react';
import projectServices from 'app/services/project-services';
import { Theme } from 'app/types/theme';
import { formatNumber, formatCurrency } from 'app/utils/formatters';
import { theme } from 'app/utils/theme';
interface PenaltyData {
  squareFeet: number;
  annualThresholdCO2: number;
  yearsData: Array<{
    year: string;
    energySums: {
      electric: number;
      gas: number;
      steam: number;
      oil: number;
    };
    emissionsByType: {
      electric: number;
      gas: number;
      steam: number;
      oil: number;
    };
    monthsCovered: number;
    actualEmissions: number;
    threshold: number;
    eui: {
      siteEUI: number;
      sourceEUI: number;
    };
  }>;
  estimatedAnnual: {
    energySums: {
      electric: number;
      gas: number;
      steam: number;
      oil: number;
    };
    emissions: number;
    penalty: number;
    emissionsByType: {
      electric: number;
      gas: number;
      steam: number;
      oil: number;
    };
    eui: {
      siteEUI: number;
      sourceEUI: number;
    };
  };
}

interface EstimatedPenaltyProps {
  projectId: string;
}

interface InfoCardProps {
  icon: LucideIcon;
  title: string;
  value: string;
  theme: Theme;
  colorIndex?: number;
}

const EstimatedPenalties = forwardRef<HTMLDivElement, EstimatedPenaltyProps>(({ projectId }, ref) => {
  const [penalties, setPenalties] = useState<PenaltyData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPenalties = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await projectServices.getEstimatedPenalties(projectId);
        setPenalties(data);
      } catch (err) {
        // setError('Failed to fetch penalty data. Please try again later.');
        console.error('Error fetching penalties:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPenalties();
  }, [projectId]);

  if (isLoading) {
    return (
      <div className="flex h-[100%] items-center justify-center py-8">
        <Loader2 className={cn('h-20 w-20 animate-spin text-primary')} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-[100%] items-center justify-center py-8">
        <div className="rounded-xl bg-white/10 p-8 text-center shadow-lg backdrop-blur-lg backdrop-filter">
          <AlertTriangle className="mx-auto mb-4 h-12 w-12 text-red-500" />
          <div className="text-lg font-semibold">Error: {error}</div>
        </div>
      </div>
    );
  }

  if (!penalties) {
    return (
      <div className="flex h-[100%] items-center justify-center p-8">
        <div className="max-w-2xl rounded-xl bg-white/10 p-8 text-center shadow-lg backdrop-blur-lg backdrop-filter">
          <Building2 className="mx-auto mb-4 h-12 w-12 text-primary" />
          <h3 className="mb-2 text-xl font-semibold">No Estimations Available</h3>
          <p className="text-sm text-muted-foreground">
            Upload utility bills for this project to start analyzing your energy usage, carbon emissions, and estimated
            penalties.
          </p>
        </div>
      </div>
    );
  }

  const { squareFeet, annualThresholdCO2, yearsData, estimatedAnnual } = penalties;

  const latestYear = yearsData[yearsData.length - 1];
  const estimatedPenalty = estimatedAnnual.penalty;

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center gap-2 px-4 pb-2">
        <h2 className="font-bold">Energy Usage and Carbon Emissions Analysis</h2>
      </div>

      <ScrollArea orientation="vertical" className="grow p-4">
        <div className="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
          <InfoCard
            icon={Building2}
            title="Building Size"
            value={`${formatNumber(squareFeet)} sq ft`}
            theme={theme}
            colorIndex={0}
          />
          <InfoCard
            icon={TrendingDown}
            title="Annual CO₂ Threshold"
            value={`${formatNumber(annualThresholdCO2)} tCO₂`}
            theme={theme}
            colorIndex={1}
          />
          <InfoCard
            icon={AlertTriangle}
            title="Est. Annual Emissions"
            value={`${formatNumber(estimatedAnnual.emissions)} tCO₂`}
            theme={theme}
            colorIndex={2}
          />
          <InfoCard
            icon={DollarSign}
            title="Est. Annual Penalty"
            value={formatCurrency(estimatedPenalty)}
            theme={theme}
            colorIndex={3}
          />
        </div>

        <div className="mb-8 rounded-xl bg-white/10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
          <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
            Emissions Trend Analysis
          </h2>
          <ResponsiveContainer width="100%" height={350}>
            <LineChart data={yearsData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" stroke={theme.text.secondary} />
              <XAxis dataKey="year" stroke={theme.text.secondary} />
              <YAxis
                yAxisId="left"
                orientation="left"
                stroke={theme.text.secondary}
                tickFormatter={(value) => formatNumber(value)}
              />
              <Tooltip
                formatter={(value) => {
                  return formatNumber(value as number);
                }}
                contentStyle={{ backgroundColor: theme.card.background, borderColor: theme.text.secondary }}
              />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="actualEmissions"
                name="Actual Emissions"
                stroke={theme.chart.colors[0]}
                activeDot={{ r: 8 }}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="threshold"
                name="Emissions Threshold"
                stroke={theme.chart.colors[1]}
                strokeDasharray="5 5"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="mb-8 rounded-xl bg-white/10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
          <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
            Energy Usage Breakdown
          </h2>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={yearsData} margin={{ top: 10, right: 20, left: 0, bottom: 0 }} className="p-4">
              <CartesianGrid strokeDasharray="3 3" stroke={theme.text.secondary} />
              <XAxis dataKey="year" stroke={theme.text.secondary} />
              <YAxis stroke={theme.text.secondary} tickFormatter={(value) => formatNumber(value)} />
              <Tooltip
                formatter={(value) => {
                  return formatNumber(value as number);
                }}
                contentStyle={{ backgroundColor: theme.card.background, borderColor: theme.text.secondary }}
              />
              <Legend />
              <Bar dataKey="energySumsKBTU.electric" name="Electricity" stackId="a" fill={theme.chart.colors[0]} />
              <Bar dataKey="energySumsKBTU.gas" name="Natural Gas" stackId="a" fill={theme.chart.colors[1]} />
              <Bar dataKey="energySumsKBTU.steam" name="Steam" stackId="a" fill={theme.chart.colors[2]} />
              <Bar dataKey="energySumsKBTU.oil" name="Oil" stackId="a" fill={theme.chart.colors[3]} />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="mb-8 overflow-x-auto rounded-xl bg-white/10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
          <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
            Yearly Energy and Emissions Summary
          </h2>
          <table className="w-full text-left">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Year
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Electricity (kWh)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Electricity (tCO₂)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Natural Gas (Therms)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Natural Gas (tCO₂)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Steam (mLbs)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Steam (tCO₂)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Oil (gallons)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Oil (tCO₂)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Total Emissions (tCO₂)
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Site EUI
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Source EUI
                </th>
                <th className="p-2 text-sm font-semibold" style={{ color: theme.text.primary }}>
                  Months Covered
                </th>
              </tr>
            </thead>
            <tbody>
              {yearsData.map((year, index) => (
                <tr
                  key={year.year}
                  className={cn({
                    'bg-white/5': index % 2 === 0,
                  })}
                >
                  <td className="p-2 text-sm font-medium" style={{ color: theme.text.primary }}>
                    {year.year}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.energySums.electric)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.emissionsByType.electric)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.energySums.gas)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.emissionsByType.gas)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.energySums.steam)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.emissionsByType.steam)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.energySums.oil)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.emissionsByType.oil)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.actualEmissions)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.eui.siteEUI)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {formatNumber(year.eui.sourceEUI)}
                  </td>
                  <td className="p-2 text-sm" style={{ color: theme.text.secondary }}>
                    {year.monthsCovered}
                  </td>
                </tr>
              ))}
              <tr className="bg-blue-500/10 font-semibold">
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  Estimated Annual
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.energySums.electric)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.emissionsByType.electric)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.energySums.gas)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.emissionsByType.gas)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.energySums.steam)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.emissionsByType.steam)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.energySums.oil)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.emissionsByType.oil)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.emissions)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.eui.siteEUI)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  {formatNumber(estimatedAnnual.eui.sourceEUI)}
                </td>
                <td className="p-2 text-sm" style={{ color: theme.text.primary }}>
                  12
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="rounded-xl bg-white bg-opacity-10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
          <h2 className="mb-4 text-xl font-bold" style={{ color: theme.text.primary }}>
            Recommendations
          </h2>
          <ul className="space-y-2 text-sm" style={{ color: theme.text.secondary }}>
            <li>
              Your estimated annual emissions ({formatNumber(estimatedAnnual.emissions)} tCO₂) are{' '}
              {estimatedAnnual.emissions > annualThresholdCO2 ? 'above' : 'below'} the threshold (
              {formatNumber(annualThresholdCO2)} tCO₂).
            </li>
            {estimatedAnnual.emissions > annualThresholdCO2 && (
              <li>
                Consider implementing energy efficiency measures to reduce your emissions and avoid potential penalties.
              </li>
            )}
            <li>
              Focus on reducing{' '}
              {latestYear?.energySums.electric > 0
                ? 'electricity'
                : latestYear?.energySums.steam > 0
                  ? 'steam'
                  : 'natural gas'}{' '}
              usage, as it's your primary source of emissions.
            </li>
            <li>Regularly monitor your energy consumption and emissions to stay on track with your reduction goals.</li>
            <li>Explore renewable energy options to further decrease your carbon footprint.</li>
          </ul>
        </div>
      </ScrollArea>
    </div>
  );
});

const InfoCard = ({ icon: Icon, title, value, theme, colorIndex = 0 }: InfoCardProps) => {
  return (
    <Card className="rounded-xl border-border bg-white/10 transition-all duration-300 ease-in-out hover:border-border/5 hover:bg-white/5">
      <CardHeader>
        <CardTitle className="flex flex-row gap-2 text-lg">
          <Icon color={theme.text.highlight} className="h-6 w-6" />
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent
        className="text-2xl font-bold"
        style={{
          color: theme.chart.colors[colorIndex],
        }}
      >
        {value}
      </CardContent>
    </Card>
  );
};

export default EstimatedPenalties;
