import { forwardRef, useEffect, useState } from 'react';
import { ScrollArea } from '@shadcn/ui';
import projectServices from 'app/services/project-services';
import energyStarServices from 'app/services/energy-star-services';
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner';
import { ErrorMessage } from 'app/components/ui/ErrorMessage';
import { EnergyStarScoreCard } from 'app/components/energy-star/EnergyStarScoreCard';
import { UtilitiesSummary } from 'app/components/project/UtilitiesSummary';
import { EnergyStarMeters } from 'app/components/energy-star/EnergyStarMeters';
import { theme } from 'app/utils/theme';
import type { FlattenedAlert, PropertyMetrics } from 'app/services/energy-star-services';
import type { PenaltyData } from '../../../types/penalty-data';
import type { Meter, ConsumptionData } from '../../../types/energy-star';

type EstimatedPenaltyProps = {
  projectId: string;
};

const useProjectData = (projectId: string) => {
  const [project, setProject] = useState<any | null>(null);
  const [penalties, setPenalties] = useState<PenaltyData | null>(null);
  const [error, setError] = useState<{ project?: string; penalties?: string } | null>(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectData = await projectServices.getById(projectId);
        setProject(projectData);
      } catch (err) {
        setError((prev) => ({ ...prev, project: 'Failed to fetch project data' }));
        console.error('Error fetching project data:', err);
      }
    };

    const fetchPenalties = async () => {
      try {
        const penaltyData = await projectServices.getEstimatedPenalties(projectId);
        setPenalties(penaltyData);
      } catch (err) {
        console.error('Error fetching penalty data:', err);
      }
    };

    fetchProject();
    fetchPenalties();
  }, [projectId]);

  return { project, penalties, error };
};

const useEnergyStarData = (energyStarId: string | null) => {
  const fallbackData = {
    meters: [],
    consumptions: {},
    energyStarScore: { score: null },
    noScoreReason: [
      {
        name: 'Project not connected to Energy Star',
        description: 'Sync project with a property in Energy Star to see score.',
      },
    ],
    error: null,
  };

  const [meterData, setMeterData] = useState<{
    meters: Meter[];
    consumptions: { [meterId: string]: ConsumptionData };
    energyStarScore?: PropertyMetrics;
    noScoreReason?: FlattenedAlert[];
  }>(fallbackData);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Return early with fallback values if no energyStarId
    if (!energyStarId) {
      setMeterData(fallbackData);
      return;
    }

    const fetchMeterData = async () => {
      try {
        // TODO: get the year and month from the project or make configurable
        const energyStarScoreResponse = await energyStarServices.getPropertyScore(energyStarId, '2023', '12');
        const noScoreReasonResponse = await energyStarServices.getNoScoreReasons(energyStarId);
        console.log('energyStarScoreResponse', energyStarScoreResponse);
        console.log('noScoreReasonResponse', noScoreReasonResponse);

        // Get all meters for the property
        const metersResponse = await energyStarServices.getMeters(energyStarId);
        const meters = metersResponse['meters'];
        // Get consumption data for each meter
        const consumptions = await Promise.all(
          meters.map(async (meter) => {
            try {
              const consumption = await energyStarServices.getMeterConsumption(meter.id);
              return { meterId: meter.id, consumption };
            } catch (err) {
              console.warn(`Failed to fetch consumption for meter ${meter.id}:`, err);
              return { meterId: meter.id, consumption: { meterConsumption: [] } };
            }
          })
        );

        // Convert to object map for easier lookup
        const consumptionMap = consumptions.reduce(
          (acc, { meterId, consumption }) => {
            acc[meterId] = consumption;
            return acc;
          },
          {} as { [key: string]: ConsumptionData }
        );

        setMeterData({
          meters,
          consumptions: consumptionMap,
          energyStarScore: energyStarScoreResponse,
          noScoreReason: noScoreReasonResponse,
        });
      } catch (err) {
        console.error('Error fetching meter data:', err);
        // setError('Failed to fetch meter data');
      }
    };

    fetchMeterData();
  }, [energyStarId]);

  return { ...meterData, error };
};

const Benchmarks = forwardRef<HTMLDivElement, EstimatedPenaltyProps>(({ projectId }, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const { project, penalties, error: projectErrors } = useProjectData(projectId);
  const {
    meters,
    consumptions,
    energyStarScore,
    noScoreReason,
    error: energyStarError,
  } = useEnergyStarData(project?.attributes?.energyStarId);

  useEffect(() => {
    if (project !== null || projectErrors?.project) {
      setIsLoading(false);
    }
  }, [project, projectErrors]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  console.log('penalties', penalties);

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center gap-2 px-4 pb-2">
        <h2 className="font-bold">Energy Benchmarking</h2>
      </div>

      <ScrollArea orientation="vertical" className="grow p-4">
        {projectErrors?.project && <ErrorMessage message={projectErrors.project} />}
        {projectErrors?.penalties && <ErrorMessage message={projectErrors.penalties} />}
        {energyStarError && <ErrorMessage message={energyStarError} />}

        <EnergyStarScoreCard score={energyStarScore} noScoreReason={noScoreReason} theme={theme} />

        <UtilitiesSummary yearsData={penalties?.yearsData} estimatedAnnual={penalties?.estimatedAnnual} theme={theme} />

        <EnergyStarMeters meters={meters} consumptionData={consumptions} theme={theme} displayEmptyMeters={false} />
      </ScrollArea>
    </div>
  );
});

export default Benchmarks;
