import { useState } from 'react';
import { useTasks } from 'app/hooks/useTasks';
import { useProjects } from 'app/hooks/useProjects';
import energyStarServices from 'app/services/energy-star-services';
import syncServices from 'app/services/sync-services';
import { RightSidebar } from 'app/layouts/shared/RightSidebar';
import { MainContent } from 'app/components/home/MainContent';
import type { Task } from 'app/types/task';
import type { ProjectInfoData } from 'app/services/project-services';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useToast } from '../../../@shadcn/hooks/use-toast';
import { extractError } from 'app/utils/appHelpers';

export const Home = () => {
  const { authUser } = useJumboAuth();
  const energyStarAccountId = authUser?.relationships?.organization?.data?.energyStarAccountId;

  const tasks = useTasks(authUser);
  const { projects, setRefreshProjects } = useProjects();

  const [energyStarProperties, setEnergyStarProperties] = useState<any[]>([]);
  const [showProjectsTable, setShowProjectsTable] = useState(false);
  const [showEnergyStarProperties, setShowEnergyStarProperties] = useState(false);
  const [propertyScores, setPropertyScores] = useState<Record<string, number>>({});
  const [reviewingProject, setReviewingProject] = useState<{ projectId: string; isVisible: boolean } | null>(null);
  const [syncedProperties, setSyncedProperties] = useState<Set<string>>(new Set());
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const { toast } = useToast();

  const handlePromptClick = async (
    promptMessage: string,
    aiResponse: string,
    action?: () => Promise<void>,
    showTable: boolean = false,
    showEnergyStarProps: boolean = false
  ) => {
    if (action) {
      try {
        await action();
      } catch (error) {
        console.error('Error executing action:', error);
      }
    }

    setShowEnergyStarProperties(false);
    setShowProjectsTable(false);
    setReviewingProject(null);
    setPropertyScores({});

    setTimeout(() => {
      if (showTable) setShowProjectsTable(true);
      if (showEnergyStarProps) setShowEnergyStarProperties(true);
    }, 100);
  };

  const handleSyncWithEnergyStarClick = async () => {
    try {
      const [properties] = await Promise.all([energyStarServices.listProperties(energyStarAccountId)]);
      console.log('Properties:', properties);
      setEnergyStarProperties(properties['properties']);
      setShowEnergyStarProperties(true);
      setShowProjectsTable(false);
    } catch (error) {
      console.error('Error syncing with Energy Star Account:', error);
    }
  };

  const handleSyncEnergyStarProperty = async (customerId: string, propertyId: string, parentId?: string) => {
    try {
      const energyStarCustomerId = customerId || energyStarAccountId;
      await syncServices.syncWithEnergyStar(energyStarCustomerId, propertyId, parentId);
      setSyncedProperties((prev) => new Set(prev).add(propertyId));
    } catch (error) {
      console.error('Error syncing project:', error);
      handleSnackbar(extractError(error) ?? 'Failed to sync Energy Star property', 'destructive');
    }
  };

  const handleSnackbar = (message: string, variant: 'default' | 'destructive' | 'positive') => {
    toast({
      description: message,
      variant,
    });
  };

  return (
    <div className="flex h-screen">
      <MainContent
        tasks={tasks}
        projects={projects}
        showProjectsTable={showProjectsTable}
        showEnergyStarProperties={showEnergyStarProperties}
        propertyScores={propertyScores}
        setRefreshProjects={() => setRefreshProjects((prev) => !prev)}
        reviewingProject={reviewingProject}
        energyStarProperties={energyStarProperties}
        syncedProperties={syncedProperties}
        onTaskClick={setSelectedTask}
        onReviewClick={(project: ProjectInfoData) =>
          setReviewingProject({
            projectId: project.id,
            isVisible: true,
          })
        }
        onSubmitScore={(propertyName: string) => setPropertyScores((prev) => ({ ...prev, [propertyName]: 75 }))}
        onPropertySync={handleSyncEnergyStarProperty}
        onReviewClose={() => setReviewingProject(null)}
        onSnackbar={handleSnackbar}
      />
      <RightSidebar
        handlePromptClick={handlePromptClick}
        handleSyncWithEnergyStarClick={handleSyncWithEnergyStarClick}
        selectedTask={selectedTask}
      />
    </div>
  );
};
