import jwtAuthAxios from './auth/jwtAuth';

interface EnergyStarAccount {
  account: {
    id: string;
    username: string;
    webserviceUser: boolean;
    searchable: boolean;
    includeTestPropertiesInGraphics: boolean;
    emailPreferenceCanadianAccount: boolean;
    contact: {
      address: {
        address1: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
      email: string;
      firstName: string;
      phone: string;
      lastName: string;
      jobTitle: string;
    };
    organization: {
      name: string;
      primaryBusiness: string;
      otherBusinessDescription: string;
      energyStarPartner: boolean;
    };
    billboardMetric: string;
    languagePreference: string;
  };
}

interface Customer {
  id: string;
}

interface Property {
  id: string;
}

interface PropertyUse {
  id: string;
}

interface Meter {
  id: string;
}

interface ConsumptionData {
  id: string;
}

interface Building {
  id: string;
  name: string;
}

export interface PropertyMetrics {
  score: number | null;
  siteTotal?: number | null;
  sourceTotal?: number | null;
  scorePreview?: number | null;
  month?: number;
  year?: number;
  propertyId?: string;
}

export interface FlattenedAlert {
  name: string;
  description?: string | TrustedHTML;
  propertyId?: string;
}

const energyStarServices = {
  getAccount: async (): Promise<EnergyStarAccount> => {
    const { data } = await jwtAuthAxios.get(`/v1/energy-star/accounts`, {
      headers: {
        Accept: 'application/xml',
      },
      responseType: 'text',
    });
    return data;
  },

  listCustomers: async (): Promise<Customer[]> => {
    const { data } = await jwtAuthAxios.get('/v1/energy-star/customers');
    return data;
  },

  createCustomer: async (customerData: any): Promise<Customer> => {
    const { data } = await jwtAuthAxios.post('/v1/energy-star/customers', customerData);
    return data;
  },

  listProperties: async (customerId: string): Promise<Property[]> => {
    const { data } = await jwtAuthAxios.get(`/v1/energy-star/accounts/${customerId}/properties`);
    return data;
  },

  createProperty: async (customerId: string, propertyData: any): Promise<Property> => {
    const { data } = await jwtAuthAxios.post(`/v1/energy-star/accounts/${customerId}/properties`, propertyData);
    return data;
  },

  createPropertyUse: async (propertyId: string, propertyUseData: any): Promise<PropertyUse> => {
    const { data } = await jwtAuthAxios.post(`/v1/energy-star/properties/${propertyId}/propertyUse`, propertyUseData);
    return data;
  },

  listMeters: async (propertyId: string): Promise<Meter[]> => {
    const { data } = await jwtAuthAxios.get(`/v1/energy-star/properties/${propertyId}/meters`);
    return data;
  },

  createMeter: async (propertyId: string, meterData: any): Promise<Meter> => {
    const { data } = await jwtAuthAxios.post(`/v1/energy-star/properties/${propertyId}/meters`, meterData);
    return data;
  },

  getMeter: async (meterId: string): Promise<any> => {
    const { data } = await jwtAuthAxios.get(`/v1/energy-star/meters/${meterId}`);
    return data;
  },

  getMeters: async (propertyId: string): Promise<Meter[]> => {
    const { data } = await jwtAuthAxios.get(`/v1/energy-star/properties/${propertyId}/meters`);
    return data;
  },

  getMeterConsumption: async (meterId: string): Promise<any> => {
    const { data } = await jwtAuthAxios.get(`/v1/energy-star/meters/${meterId}/consumptionData`);
    return data;
  },

  addMeterConsumption: async (meterId: string, consumptionData: any): Promise<ConsumptionData> => {
    const { data } = await jwtAuthAxios.post(`/v1/energy-star/meters/${meterId}/consumptionData`, consumptionData);
    return data;
  },

  associateMeters: async (propertyId: string, meterData: any): Promise<any> => {
    const { data } = await jwtAuthAxios.post(`/v1/energy-star/association/properties/${propertyId}/meters`, meterData);
    return data;
  },

  getPropertyScore: async (propertyId: string, year: string, month: string): Promise<PropertyMetrics> => {
    const { data } = await jwtAuthAxios.get(`/v1/energy-star/properties/${propertyId}/metrics`, {
      params: {
        year,
        month,
        measurementSystem: 'EPA',
      },
    });
    return data;
  },

  getNoScoreReasons: async (propertyId: string): Promise<FlattenedAlert[]> => {
    const { data } = await jwtAuthAxios.get(`/v1/energy-star/properties/${propertyId}/noScoreReasons`);
    return data;
  },

  syncProperty: async (customerId: string, propertyId: string, parentId?: string): Promise<any> => {
    const { data } = await jwtAuthAxios.post(`/v1/energy-star/customers/${customerId}/properties/${propertyId}/sync`, {
      parentId,
    });
    return data;
  },

  listPropertyBuildings: async (propertyId: string): Promise<Building[]> => {
    const { data } = await jwtAuthAxios.get(`/v1/energy-star/properties/${propertyId}/buildings`);
    return data;
  },
};

export default energyStarServices;
