import { forwardRef, useCallback, useEffect, useState } from 'react';
import { VariantProps } from 'class-variance-authority';
import { LoaderCircleIcon } from 'lucide-react';
import { toastVariants } from '@shadcn/ui';
import energyStarServices from 'app/services/energy-star-services';
import projectServices from 'app/services/project-services';
import { EnergyStarMeters } from 'app/components/energy-star/EnergyStarMeters';
import { theme } from 'app/utils/theme';
import type { Meter, ConsumptionData } from 'app/types/energy-star';

interface MetersProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

export const Meters = forwardRef<HTMLDivElement, MetersProps>(({ projectId, onSnackbar }, ref) => {
  const [meters, setMeters] = useState<Meter[]>([]);
  const [consumptionData, setConsumptionData] = useState<Record<string, ConsumptionData>>({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchMeters = useCallback(async () => {
    try {
      const projectData = await projectServices.getById(projectId);
      if (!projectData.attributes.energyStarId) {
        return [];
      }

      const metersData = await energyStarServices.listMeters(projectData.attributes.energyStarId);
      return metersData['meters'];
    } catch (error) {
      console.error('Error fetching meters:', error);
      return [];
    }
  }, [projectId]);

  const fetchMeterConsumption = useCallback(async (meterId: string) => {
    try {
      const consumptionData = await energyStarServices.getMeterConsumption(meterId);
      return consumptionData;
    } catch (error) {
      console.error('Error fetching meter consumption:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const loadData = async () => {
      const metersData = await fetchMeters();
      if (!isMounted) return;

      setMeters(metersData);

      const consumptionPromises = metersData.map((meter) =>
        fetchMeterConsumption(meter.id).then((consumption) => ({
          meterId: meter.id,
          consumption,
        }))
      );

      const consumptionResults = await Promise.all(consumptionPromises);
      if (!isMounted) return;

      const consumptionMap = consumptionResults.reduce(
        (acc, { meterId, consumption }) => {
          if (consumption) {
            acc[meterId] = consumption;
          }
          return acc;
        },
        {} as Record<string, ConsumptionData>
      );

      setConsumptionData(consumptionMap);
      setIsLoading(false);
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [fetchMeters, fetchMeterConsumption]);

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <LoaderCircleIcon className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-2">
        <h2 className="font-bold">Meters</h2>
      </div>

      <div className="grow overflow-auto p-4">
        <EnergyStarMeters meters={meters} consumptionData={consumptionData} theme={theme} displayEmptyMeters={true} />
      </div>
    </div>
  );
});
