import type { Task } from '../types/task';

export const useTasks = (authUser: any | null) => {
  const tasks: Task[] = [];

  if (!authUser?.relationships?.organization?.data?.energyStarAccountId) {
    tasks.push({
      id: 'connect-energy-star',
      title: 'Connect Energy Star Portfolio Manager',
      subtitle: 'Share your property data with Cosmos to automatically analyze your utility data.',
      description: `
# How to Share Properties in Portfolio Manager

## 1. Connect with Contacts
- Click "Contacts" in the top right corner
- Select "Add New Contacts/Connections"
- Search for Cosmos by organization name
- Send a connection request
- Wait for Cosmos to accept your request

## 2. Share Properties
### Start Sharing
- Go to the Sharing tab
- Click "Share (or Edit Access to) a Property"
- Select the properties you want to share
  - Choose single or multiple properties
  - Use filters if needed

### Choose Sharing Permissions
- Select "Full Access" so Cosmos can view and edit property data

### Select Contacts
- Select Cosmos from your list of contacts
- Set individual or bulk permission levels

**Pro Tip:** As the Property Data Administrator, only you can delete or transfer property ownership.`,
    });
  }

  return tasks;
};
