import { Badge, Button } from '@shadcn/ui';

interface TagSelectorProps {
  availableTags: string[];
  selectedTags: string[];
  onTagSelect: (tag: string) => void;
  onClearTags: () => void;
}

export const TagSelector = ({ availableTags, selectedTags, onTagSelect, onClearTags }: TagSelectorProps) => {
  return (
    <div className="flex min-w-[300px] flex-col gap-2 rounded-xl border border-gray-200/50 bg-background/40 px-6 py-3 shadow-sm">
      <div className="flex items-center gap-2">
        <span className="text-sm font-semibold">Filter by Tags</span>
        {selectedTags.length > 0 && (
          <Button variant="ghost" size="sm" onClick={onClearTags} className="h-6 px-2 text-xs">
            Clear All
          </Button>
        )}
      </div>

      <div className="flex flex-wrap items-center gap-4">
        {availableTags.map((tag) => (
          <Badge
            key={tag}
            variant={selectedTags.includes(tag) ? 'default' : 'outline'}
            className={`cursor-pointer transition-all duration-200 hover:opacity-80 ${
              selectedTags.includes(tag) ? 'bg-primary text-primary-foreground' : 'hover:border-primary/50'
            } `}
            onClick={() => onTagSelect(tag)}
          >
            {tag}
            {selectedTags.includes(tag) && <span className="ml-1 text-xs">×</span>}
          </Badge>
        ))}
        {availableTags.length === 0 && <span className="text-sm italic text-muted-foreground">No tags available</span>}
      </div>

      {selectedTags.length > 0 && (
        <div className="mt-2 text-xs text-muted-foreground">
          Showing documents with {selectedTags.length === 1 ? 'tag' : 'tags'}: {selectedTags.join(', ')}
        </div>
      )}
    </div>
  );
};
