import { Menu } from 'app/layouts/shared/sidebar/VerticalNavbar';
import { House, SquareChartGanttIcon, SquareUserRoundIcon } from 'lucide-react';

export const menus: Menu = [
  {
    label: 'Dashboard',
    type: 'section',
    children: [
      {
        uri: '/dashboard/home',
        label: 'My Cosmos',
        type: 'nav-item',
        icon: <House className="size-5" />,
      },
      {
        uri: '/dashboard/project',
        label: 'Projects',
        type: 'nav-item',
        icon: <SquareChartGanttIcon className="size-5" />,
      },
    ],
  },
  {
    label: 'Account',
    type: 'section',
    children: [
      {
        uri: '/account/profile',
        label: 'Profile',
        type: 'nav-item',
        icon: <SquareUserRoundIcon className="size-5" />,
      },
    ],
  },
];
