import { Dispatch, forwardRef, SetStateAction, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { CloudUploadIcon, TrashIcon } from 'lucide-react';
import * as pdfjsLib from 'pdfjs-dist';
import { Button, ScrollArea, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcn/ui';
import { cn } from '@shadcn/utils';

// 2GB
const MAX_FILE_SIZE_BYTES = 2_000_000_000;

// todo: find a better place for this
export type FileWithMetadata = {
  file: File;
  fileName: string;
  fileSize: number;
  hasMultipleBills: boolean;
  lastModified: number;
  mimeType: string;
  numberOfPages: number;
};

interface DocumentUploadProps {
  files: FileWithMetadata[];
  setFiles: Dispatch<SetStateAction<FileWithMetadata[]>>;
  setError: (error: string) => void;
  handleRemoveFile: (index: number) => void;
}

export const DocumentUpload = forwardRef<HTMLDivElement, DocumentUploadProps>(
  ({ files, setFiles, setError, handleRemoveFile }, ref) => {
    const onDrop = useCallback(async (acceptedFiles: File[]) => {
      const processedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          if (file.size > MAX_FILE_SIZE_BYTES) {
            setError(`File ${file.name} exceeds the maximum size limit of 2GB.`);
            return null;
          }
          const fileBuffer = await file.arrayBuffer();
          const typedArray = new Uint8Array(fileBuffer);
          const pdf = await pdfjsLib.getDocument({ data: typedArray }).promise;
          return {
            file,
            fileName: file.name,
            mimeType: file.type,
            fileSize: file.size,
            lastModified: file.lastModified,
            numberOfPages: pdf.numPages,
            hasMultipleBills: false,
          };
        })
      );
      setFiles((prevFiles) => [...prevFiles, ...processedFiles.filter((file) => file !== null)]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: { 'application/pdf': ['.pdf'] },
      multiple: true,
      maxSize: MAX_FILE_SIZE_BYTES,
    });

    return (
      <ScrollArea ref={ref} orientation="vertical" className="flex flex-col gap-4 p-2">
        <div
          className={cn('flex cursor-pointer flex-col items-center border border-transparent bg-card p-6', {
            'border border-accent bg-accent': isDragActive,
            'border-b border-b-border': files.length > 0,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <CloudUploadIcon className="mb-4 h-12 w-12" />
          <h6>{isDragActive ? 'Drop the PDFs here' : "Drag 'n' drop PDFs here, or click to select"}</h6>
        </div>
        {files.length > 0 ? (
          <div className="mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>File Name</TableHead>
                  <TableHead className="text-right">Size</TableHead>
                  <TableHead className="text-right">Pages</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {files.map((file, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row">{file.fileName}</TableCell>
                    <TableCell className="text-right">{(file.fileSize / 1024 / 1024).toFixed(2)} MB</TableCell>
                    <TableCell className="text-right">{file.numberOfPages}</TableCell>
                    <TableCell className="text-right">
                      <Button
                        size="icon"
                        variant="ghost"
                        className="[&>svg]:h-4"
                        onClick={() => handleRemoveFile(index)}
                      >
                        <TrashIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </ScrollArea>
    );
  }
);
