import { Button } from '@shadcn/ui/button';
import { ProjectInfoData } from 'app/services/project-services';

interface ProjectsTableProps {
  projects: ProjectInfoData[];
  propertyScores: Record<string, number>;
  onReviewClick: (project: ProjectInfoData) => void;
  onSubmitScore: (propertyName: string) => void;
  onSync: (projectId: string) => void;
}

export const ProjectsTable = ({
  projects,
  propertyScores,
  onReviewClick,
  onSubmitScore,
  onSync,
}: ProjectsTableProps) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="border-b">
            <th className="px-4 py-2 text-left">Property</th>
            <th className="px-4 py-2 text-left">Energy Star ID</th>
            <th className="px-4 py-2 text-left">Benchmarks</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <tr key={project.attributes.name} className="border-b">
              <td className="px-4 py-2">{project.attributes.name}</td>
              <td className="px-4 py-2">{project.attributes.energyStarId}</td>
              <td className="px-4 py-2">
                <div className="flex items-center gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    className="border-[#6E56CF] text-[#6E56CF] transition-colors hover:bg-[#6E56CF] hover:text-white"
                    onClick={() => onReviewClick(project)}
                  >
                    Review
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
