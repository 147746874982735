import { useState, useEffect } from 'react';
import projectServices, { ProjectInfoData } from 'app/services/project-services';

export const useProjects = () => {
  const [projects, setProjects] = useState<ProjectInfoData[]>([]);
  const [refreshProjects, setRefreshProjects] = useState(false);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const projectsArray = await projectServices.get();
        if (isMounted) {
          setProjects(projectsArray);
        }
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [refreshProjects]);

  return {
    projects,
    refreshProjects,
    setRefreshProjects,
  };
};
