import React from 'react';

export const AuthContext = React.createContext<{
  authUser: any;
  authToken: string | null;
  isLoading: boolean;
  fallbackPath: string;
  setAuthToken: (token: string | null) => void;
}>({
  authUser: null,
  authToken: null,
  isLoading: false,
  fallbackPath: '',
  setAuthToken: () => {},
});
