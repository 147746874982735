import { Card, CardContent, CardDescription, CardHeader, CardTitle, Slider } from '@shadcn/ui';
import { Loader2Icon } from 'lucide-react';

interface BaseTemperatureCardProps {
  baseTemperature: number;
  onValueChange: (value: number[]) => void;
  isUpdating?: boolean;
}

export const BaseTemperatureCard = ({ baseTemperature, onValueChange, isUpdating }: BaseTemperatureCardProps) => {
  return (
    <Card className="mx-4 mb-8 animate-fade-up-in rounded-xl border-border">
      <CardHeader>
        <CardTitle>Base Temperature</CardTitle>
        <CardDescription>Adjust the base temperature for degree day calculations</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex items-center gap-4">
          <Slider min={50} max={80} step={1} value={[baseTemperature]} onValueChange={onValueChange} className="w-64" />
          <span>{baseTemperature}°F</span>
          {isUpdating && <Loader2Icon className="h-4 w-4 animate-spin" />}
        </div>
      </CardContent>
    </Card>
  );
};
