import jwtAuthAxios from './auth/jwtAuth';

const billingServices = {
  createBilling: async ({ body }) => {
    const { data } = await jwtAuthAxios.post(`/v1/billing`, body);
    return data;
  },
  getBilling: async () => {
    const { data } = await jwtAuthAxios.get('/v1/billing');
    return data;
  },
};

export default billingServices;
