import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Checkbox,
} from '@shadcn/ui';
import {
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
  EllipsisVerticalIcon,
  Trash2,
  CheckCircle2,
  AlertCircle,
  Clock,
  XCircle,
  FileIcon,
} from 'lucide-react';
import { Document } from '../Documents';
import { TagSelector } from './TagSelector';
import { ELECTRIC, GAS, STEAM, OIL, WATER } from 'app/utils/constants/utilityServices';
import { NoDataFound } from 'app/components/project/NoDataFound';

type SortColumn = 'billDate' | 'billingPeriod' | null;
type SortDirection = 'asc' | 'desc' | null;

interface DocumentsTableProps {
  documents: Document[];
  onDelete: (id: string) => Promise<{ success: boolean }>;
  selectedService?: string;
}

export const DocumentsTable = ({ documents, onDelete, selectedService }: DocumentsTableProps) => {
  const navigate = useNavigate();
  const [selectedDocs, setSelectedDocs] = useState<Set<string>>(new Set());
  const [sort, setSort] = useState<{ column: SortColumn; direction: SortDirection }>({
    column: null,
    direction: null,
  });
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const handleSelectAll = () => {
    if (selectedDocs.size === documents.length) {
      setSelectedDocs(new Set());
    } else {
      setSelectedDocs(new Set(documents.map((doc) => doc._id)));
    }
  };

  const handleSelectOne = (docId: string) => {
    const newSelected = new Set(selectedDocs);
    if (newSelected.has(docId)) {
      newSelected.delete(docId);
    } else {
      newSelected.add(docId);
    }
    setSelectedDocs(newSelected);
  };

  const handleDeleteSelected = async () => {
    const filteredDocs = sortAndFilterDocuments(documents);
    const filteredDocIds = new Set(filteredDocs.map((doc) => doc._id));

    const docsToDelete = Array.from(selectedDocs).filter((id) => filteredDocIds.has(id));

    const results = await Promise.all(
      docsToDelete.map(async (id) => {
        const result = await onDelete(id);
        return { id, success: result.success };
      })
    );

    const remainingSelected = new Set(results.filter((result) => !result.success).map((result) => result.id));
    setSelectedDocs(remainingSelected);
  };

  const sortDocuments = useCallback(
    (docs: Document[]) => {
      if (!sort.direction || !sort.column) return docs;

      return [...docs].sort((a, b) => {
        let dateA: string | undefined;
        let dateB: string | undefined;

        if (sort.column === 'billDate') {
          dateA = a.attributes.detectedData?.billing_summary?.billing_summary_date;
          dateB = b.attributes.detectedData?.billing_summary?.billing_summary_date;
        } else if (sort.column === 'billingPeriod') {
          dateA = a.attributes.detectedData?.billing_summary?.billing_start_date;
          dateB = b.attributes.detectedData?.billing_summary?.billing_start_date;
        }

        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        const dateObjA = new Date(dateA);
        const dateObjB = new Date(dateB);

        if (isNaN(dateObjA.getTime()) || isNaN(dateObjB.getTime())) {
          return 0;
        }

        const yearDiff = dateObjA.getFullYear() - dateObjB.getFullYear();
        if (yearDiff !== 0) {
          return sort.direction === 'asc' ? yearDiff : -yearDiff;
        }

        const monthDiff = dateObjA.getMonth() - dateObjB.getMonth();
        if (monthDiff !== 0) {
          return sort.direction === 'asc' ? monthDiff : -monthDiff;
        }

        const dayDiff = dateObjA.getDate() - dateObjB.getDate();
        return sort.direction === 'asc' ? dayDiff : -dayDiff;
      });
    },
    [sort]
  );

  const handleSort = (column: SortColumn) => {
    setSort((current) => ({
      column,
      direction:
        current.column === column
          ? current.direction === null
            ? 'asc'
            : current.direction === 'asc'
              ? 'desc'
              : null
          : 'asc',
    }));
  };

  const getAllTags = useCallback(() => {
    const tagSet = new Set<string>();
    documents.forEach((doc) => {
      if (doc.attributes.detectedData) {
        Object.keys(doc.attributes.detectedData).forEach((tag) => tagSet.add(tag));
      }
    });
    return Array.from(tagSet);
  }, [documents]);

  const filterDocuments = useCallback(
    (docs: Document[]) => {
      if (selectedTags.length === 0) return docs;

      return docs.filter((doc) => {
        if (!doc.attributes.detectedData) return false;
        return selectedTags.every((tag) => Object.keys(doc.attributes.detectedData || {}).includes(tag));
      });
    },
    [selectedTags]
  );

  const sortAndFilterDocuments = useCallback(
    (docs: Document[]) => {
      const filteredDocs = filterDocuments(docs);
      return sortDocuments(filteredDocs);
    },
    [filterDocuments, sortDocuments]
  );

  const handleTagSelect = (tag: string) => {
    setSelectedTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      }
      return [...prev, tag];
    });
  };

  const getFilteredSelectedCount = useCallback(() => {
    const filteredDocs = sortAndFilterDocuments(documents);
    const filteredDocIds = new Set(filteredDocs.map((doc) => doc._id));
    return Array.from(selectedDocs).filter((id) => filteredDocIds.has(id)).length;
  }, [documents, selectedDocs, sortAndFilterDocuments]);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'success':
        return <CheckCircle2 className="h-5 w-5 text-green-500" />;
      case 'processing':
        return <Clock className="h-5 w-5 text-blue-500" />;
      case 'error':
        return <XCircle className="h-5 w-5 text-red-500" />;
      default:
        return <AlertCircle className="h-5 w-5 text-yellow-500" />;
    }
  };

  const getMeterDetail = (file: Document) => {
    if (!selectedService || selectedService === 'All') return null;
    const serviceData = file.attributes.detectedData?.[selectedService.toLowerCase()];
    const meterDetail = serviceData?.meter_detail;
    if (selectedService === GAS && meterDetail?.therm_conversion_factor) {
      return {
        ...meterDetail,
      };
    }

    return meterDetail;
  };

  const getUsageUnit = (service: string) => {
    switch (service) {
      case ELECTRIC:
        return 'kWh';
      case GAS:
        return 'therms';
      case STEAM:
        return 'Mlb';
      case WATER:
        return 'CCF';
      default:
        return '';
    }
  };

  const getDemandUnit = (service: string) => {
    switch (service) {
      case ELECTRIC:
        return 'kW';
      default:
        return '';
    }
  };

  if (documents.length === 0) {
    return (
      <NoDataFound
        message={`No ${selectedService?.toLowerCase() || ''} bills found`}
        submessage={`There are no ${selectedService?.toLowerCase() || ''} utility bills to display`}
        icon={<FileIcon className="h-8 w-8 text-muted-foreground" />}
      />
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        {selectedDocs.size > 0 && (
          <Button variant="destructive" size="sm" onClick={handleDeleteSelected} className="flex items-center gap-2">
            <Trash2 size={16} />
            Delete Selected {getFilteredSelectedCount()}
            {selectedDocs.size !== getFilteredSelectedCount() && (
              <span className="text-sm opacity-75">of {selectedDocs.size}</span>
            )}
          </Button>
        )}

        <TagSelector
          availableTags={getAllTags()}
          selectedTags={selectedTags}
          onTagSelect={handleTagSelect}
          onClearTags={() => setSelectedTags([])}
        />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[50px]">
              <Checkbox
                checked={selectedDocs.size === documents.length && documents.length > 0}
                onCheckedChange={handleSelectAll}
              />
            </TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Vendor</TableHead>
            <TableHead>
              <Button
                variant="ghost"
                onClick={() => handleSort('billDate')}
                className="flex items-center gap-1 hover:bg-transparent"
              >
                Bill Date
                {sort.column !== 'billDate' && <ArrowUpDown size={16} className="opacity-50" />}
                {sort.column === 'billDate' && sort.direction === 'asc' && <ArrowUp size={16} />}
                {sort.column === 'billDate' && sort.direction === 'desc' && <ArrowDown size={16} />}
              </Button>
            </TableHead>
            <TableHead>
              <Button
                variant="ghost"
                onClick={() => handleSort('billingPeriod')}
                className="flex items-center gap-1 hover:bg-transparent"
              >
                Billing Period
                {sort.column !== 'billingPeriod' && <ArrowUpDown size={16} className="opacity-50" />}
                {sort.column === 'billingPeriod' && sort.direction === 'asc' && <ArrowUp size={16} />}
                {sort.column === 'billingPeriod' && sort.direction === 'desc' && <ArrowDown size={16} />}
              </Button>
            </TableHead>
            <TableHead>Total Amount Due</TableHead>
            {selectedService && selectedService !== 'All' && (
              <>
                {getDemandUnit(selectedService) && (
                  <TableHead>Total Demand ({getDemandUnit(selectedService)})</TableHead>
                )}
                <TableHead>Total Usage ({getUsageUnit(selectedService)})</TableHead>
                {selectedService === WATER && <TableHead>Total Sewage Usage (CCF)</TableHead>}
                <TableHead>Read Type</TableHead>
              </>
            )}
            <TableHead>Status</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortAndFilterDocuments(documents).map((file) => (
            <TableRow key={file._id}>
              <TableCell>
                <Checkbox checked={selectedDocs.has(file._id)} onCheckedChange={() => handleSelectOne(file._id)} />
              </TableCell>
              <TableCell className="font-medium">{file.attributes.fileName}</TableCell>
              <TableCell>{file.attributes.detectedData?.billing_summary?.vendor_name || '-'}</TableCell>
              <TableCell>
                {file.attributes.detectedData?.billing_summary?.billing_summary_date
                  ? new Date(file.attributes.detectedData.billing_summary.billing_summary_date).toLocaleDateString()
                  : '-'}
              </TableCell>
              <TableCell>
                {file.attributes.detectedData?.billing_summary?.billing_start_date &&
                file.attributes.detectedData?.billing_summary?.billing_end_date
                  ? `${new Date(file.attributes.detectedData.billing_summary.billing_start_date).toLocaleDateString()} - 
                     ${new Date(file.attributes.detectedData.billing_summary.billing_end_date).toLocaleDateString()}`
                  : '-'}
              </TableCell>
              <TableCell>
                {file.attributes.detectedData?.billing_summary?.total_this_billing_period
                  ? `$${file.attributes.detectedData.billing_summary.total_this_billing_period.toFixed(2)}`
                  : '-'}
              </TableCell>
              {selectedService && selectedService !== 'All' && (
                <>
                  {getDemandUnit(selectedService) && (
                    <TableCell>
                      {getMeterDetail(file)?.total_demand_kw || getMeterDetail(file)?.total_demand || '-'}
                    </TableCell>
                  )}
                  <TableCell>
                    {getMeterDetail(file)?.total_usage_kwh?.toLocaleString() ||
                      getMeterDetail(file)?.total_gas_use_therms?.toLocaleString() ||
                      getMeterDetail(file)?.total_steam_use_mlb?.toLocaleString() ||
                      getMeterDetail(file)?.total_usage?.toLocaleString() ||
                      getMeterDetail(file)?.total_usage_ccf?.toLocaleString() ||
                      '-'}
                  </TableCell>
                  {selectedService === WATER && (
                    <TableCell>{getMeterDetail(file)?.total_sewer_usage_ccf?.toLocaleString() || '-'}</TableCell>
                  )}
                  <TableCell>{getMeterDetail(file)?.read_type || '-'}</TableCell>
                </>
              )}
              <TableCell>
                <div className="flex items-center gap-2">{getStatusIcon(file.attributes.status)}</div>
              </TableCell>
              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button size="icon" variant="ghost">
                      <EllipsisVerticalIcon />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => navigate(`/dashboard/document/${file._id}`)}>
                      View Details
                    </DropdownMenuItem>
                    <DropdownMenuItem variant="destructive" onClick={() => onDelete(file._id)}>
                      Remove from Project
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
