import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@shadcn/ui';

const Privacy = () => {
  return (
    <Card className="border-0">
      <CardHeader>
        <CardTitle>Privacy Policy for Cosmos Technologies, Inc</CardTitle>
        <CardDescription>Effective Date: 08/03/2023</CardDescription>
      </CardHeader>
      <CardContent className="whitespace-pre-line">
        {`Welcome to Cosmos Technologies, Inc ("we," "our," or "us"). We are committed to protecting the privacy of our users ("you" or "your") and providing a safe online experience for all our customers. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you access or use our web application available at www.app.golivecosmos.com ("the Website").

            By using the Website, you consent to the practices described in this Privacy Policy. If you disagree with any part of this Privacy Policy, please do not use our services.

            Information We Collect
            We collect various types of information to provide and improve our services. The information we collect includes:

            Personal Information: When you sign up for an account, we may collect your name, email address, billing address, and payment details.
            Usage Information: We may collect information about how you interact with the Website, including the pages you visit, the features you use, and other actions you take while using our services.
            Device Information: We may collect information about your device, such as your IP address, browser type, operating system, and other technical details.
            Cookies and Similar Technologies: We may use cookies and similar technologies to collect information about your activities on the Website. This helps us enhance your user experience and improve our services.

            How We Use Your Information
            We use the information we collect for various purposes, including:

            Providing and improving our services
            Personalizing your experience
            Responding to your inquiries and support requests
            Sending you important updates and notifications about the Website
            Conducting research and analysis to improve our services

            Sharing Your Information
            We may share your personal information with third parties in the following circumstances:

            Service Providers: We may engage third-party service providers to assist us in delivering our services. These service providers will have access to your personal information but are obligated not to disclose or use it for any other purpose.
            Business Transfers: If Cosmos Technologies, Inc. is involved in a merger, acquisition, or sale of all or a portion of its assets, your information may be transferred as part of that transaction.
            Compliance with Law: We may disclose your information to comply with applicable laws, regulations, legal processes, or governmental requests.
            Data Security
            We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.

            Your Choices
            You have the right to access, correct, or delete your personal information held by Cosmos Technologies Inc. You can update your account settings or contact us at support@golivecosmos.com to exercise your rights. You may also unsubscribe from marketing communications at any time by following the instructions in the emails we send you or by contact us directly.

            Third-Party Links
            Our Website may contain links to third-party websites or services that are not owned or controlled by Cosmos Technologies Inc. We are not responsible for the privacy practices of these third-party websites. We encourage you to review the privacy policies of any third-party websites you visit.

            Children's Privacy
            Our services are not intended for children under the age of 13. We do not knowingly collect or solicit personal information from anyone under the age of 13. If you believe that we have collected personal information from a child under 13, please contact us at support@golivecosmos.com, and we will take steps to delete the information promptly.

            Changes to This Privacy Policy
            We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated Privacy Policy on the Website and updating the "Effective Date" at the top of this page. Please review this Privacy Policy periodically to stay informed about how we are protecting and using your information.

            Contact Us
            If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at support@golivecosmos.com.

            By using our Website, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our Website. Your continued use of the Website following the posting of changes to this policy will be deemed your acceptance of those changes.`}
      </CardContent>
      <CardFooter>Last Updated: 08/03/2023</CardFooter>
    </Card>
  );
};

export { Privacy };
