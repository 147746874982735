import { AxiosError } from 'axios';
import { USE_IMAGE_PLACEHOLDERS } from './constants/paths';

export const getAssetPath = (url: string, size?: string) => {
  if (USE_IMAGE_PLACEHOLDERS) {
    return `https://via.placeholder.com/${size}.png`;
  }

  return url;
};

export const isAxiosError = (error: unknown): error is AxiosError => {
  return error instanceof Error && 'isAxiosError' in error;
};

export const extractError = (error: unknown): string | null => {
  if (error instanceof Error) {
    if (isAxiosError(error)) {
      return error.response?.data?.message;
    }
    return error.message;
  }

  return null;
};
