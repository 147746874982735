import jwtAuthAxios from './auth/jwtAuth';

interface CreateDocumentParams {
  files: {
    file: File;
    fileName: string;
    hasMultipleBills: boolean;
    mimeType?: string;
  }[];
  projectId: string;
  billBreaks: Record<string, number[]>;
}

export type UploadedDocument = {
  id: string;
  url: string;
  email: string;
  attributes: {
    createdAt: string;
    key: string;
    mimeType: string;
    numberOfPages: number;
    uploadUrl: string;
    fileName: string;
    createdBy: string;
    lastModified: string;
    documentType: string;
    status: 'queued' | 'processing' | 'success' | 'error';
    detectedData: Record<string, any>;
  };
  userDetails: {
    email: string;
  };
};

const documentServices = {
  create: async (params: CreateDocumentParams) => {
    const { data } = await jwtAuthAxios.post('/v1/documents', params);
    return data;
  },

  get: async (params: {
    page: number;
    limit: number;
    sort: string;
    order: 'asc' | 'desc';
    searchTerm: string;
  }): Promise<{ documents: UploadedDocument[]; totalCount: number }> => {
    const { data } = await jwtAuthAxios.post('/v1/documents/query', params);
    return data;
  },

  getById: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/documents/${id}`);
    return data;
  },

  getByName: async (params) => {
    const { data } = await jwtAuthAxios.post(`/v1/documents/search`, params);
    return data;
  },

  getByProjectId: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/documents/project/${id}`);
    return data;
  },

  update: async (params): Promise<UploadedDocument> => {
    return await jwtAuthAxios.put(`/v1/documents/${params.id}`, params.data);
  },

  delete: async (id) => {
    return await jwtAuthAxios.delete(`/v1/documents/${id}`);
  },
};

export default documentServices;
