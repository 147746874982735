import { ReactNode } from 'react';
import { Card, CardContent } from '@shadcn/ui';

import { ASSET_IMAGES } from 'app/utils/constants/paths';

// TODO: set up nav links and footer
export const AuthPageShell = ({ rightContent, leftContent }: { rightContent?: ReactNode; leftContent?: ReactNode }) => {
  return (
    <div className="flex h-screen w-screen animate-fade-in flex-col">
      <header className="flex flex-col items-center justify-center gap-6 bg-background py-4 sm:flex-row sm:items-stretch sm:justify-between sm:gap-0 sm:pl-40">
        <a href="/">
          <img style={{ width: '140px' }} src={`${ASSET_IMAGES}/cosmos-logo.svg`} alt="Cosmos" />
        </a>
        <div className="flex flex-row items-center gap-8 text-base sm:mr-40">
          {/*
          <>
            <Button asChild>
              <AppLink className="px-3 py-2 no-underline" to="/user/login">
                Login
              </AppLink>
            </Button>
            <a
              className="m-0 text-base tracking-wide text-muted-foreground no-underline hover:text-primary-foreground"
              style={{ textDecoration: 'none', color: '#C5CDE6' }}
              href="https://cosmosai.substack.com/"
            >
              Newsletter
            </a>
          </>
          */}
        </div>
      </header>
      <main className="mb-5 flex h-auto grow flex-col items-center justify-center border-t border-t-[#CECECE] bg-background">
        <Card className="hidden w-[400px] flex-col justify-center gap-8 rounded-3xl border-border sm:flex">
          <CardContent className="p-6">{rightContent}</CardContent>
        </Card>
        <p className="m-4 rounded-lg bg-primary p-4 text-center text-xl font-bold text-[#ddd] sm:hidden">
          Open Cosmos on desktop <br /> to sign up
        </p>
      </main>
    </div>
  );
};
