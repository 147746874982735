import { ClipboardIcon } from 'lucide-react';

interface NoDataFoundProps {
  message?: string;
  submessage?: string;
  icon?: React.ReactNode;
}

export const NoDataFound = ({
  message = 'No data found',
  submessage = 'No data is available for this section',
  icon = <ClipboardIcon className="h-8 w-8 text-muted-foreground" />,
}: NoDataFoundProps) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4 text-center">
      <div className="rounded-full bg-muted p-4">{icon}</div>
      <div className="max-w-[400px] space-y-2">
        <p className="font-semibold text-muted-foreground">{message}</p>
        <p className="text-sm text-muted-foreground">{submessage}</p>
      </div>
    </div>
  );
};
