import ReactGA from 'react-ga4';
import { config } from 'app/config/main';

const initializeGoogleAnalytics = () => {
  ReactGA.initialize(config.env.GA_TRACKING_ID ?? '', {
    gaOptions: { siteSpeedSampleRate: 100 },
  });
};

const trackGoogleAnalyticsEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export { initializeGoogleAnalytics, trackGoogleAnalyticsEvent };
