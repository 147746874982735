import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikInputWrapper } from '@shadcn/custom/FormikInputWrapper';

import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { Button } from '@shadcn/ui';
import { isAxiosError } from 'app/utils/appHelpers';
import { AuthPageShell } from 'app/pages/auth-pages/AuthPageShell';
import authServices from 'app/services/auth-services';
import userServices from 'app/services/user-services';

const validationSchema = yup.object({ password: yup.string().required('Password is required') });

const typeToConfig = {
  default: {
    tokenLackingErrorMessage: 'Temporary token not found. Please try again!',
    userNotFoundErrorMessage: 'User not found with temporary token. Please try again!',
    welcomeMessage: 'Welcome to Cosmos! Set a password to finish setting up your Cosmos account.',
  },
};

export const SetPassword = () => {
  const { setAuthToken } = useJumboAuth();
  const { tempToken } = useParams();
  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState('');

  const config = typeToConfig.default;

  const onSubmit = async (password: string) => {
    if (!tempToken) {
      setServerMessage(config.tokenLackingErrorMessage);
      return;
    }

    try {
      const currentUser = await authServices.getUserByTempToken(tempToken);
      if (!currentUser) {
        setServerMessage(config.userNotFoundErrorMessage);
        return;
      }

      setAuthToken(tempToken);
      const { organizationId } = jwtDecode<{ organizationId: string }>(tempToken);

      // if the currentUser org does not match the token org then this is an existing user accepting an invite to an org.
      const reassignOrg = currentUser.relationships.organization.data.id !== organizationId;
      const updatedUser = await userServices.update({
        id: currentUser.id,
        data: { password, organization: organizationId, isTemporary: false, reassignOrg },
      });
      if (updatedUser?.attributes?.token) {
        setAuthToken(updatedUser?.attributes?.token);
        navigate('/');
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data?.errors;
        const errorObject = errors[0];
        const message = errorObject?.detail;
        setServerMessage(message);
      }
    }
  };

  const renderLeftContent = () => (
    <>
      <h4 className="text-bold mb-4 text-center text-xl text-primary-foreground">Set Password</h4>
      <p className="mb-4 max-w-[270px] text-center">{config.welcomeMessage}</p>
    </>
  );

  const renderForm = () => (
    <div className="flex h-full min-w-0 flex-col">
      <Formik
        validateOnChange={true}
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(data.password);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="text-left" noValidate autoComplete="off">
            <FormikInputWrapper className="mt-2 w-full" name="password" label="Password" type="password" />
            {serverMessage ? <p className="my-4">{serverMessage}</p> : null}
            <Button className="mt-6 w-full" type="submit" size="lg" loading={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );

  return <AuthPageShell leftContent={renderLeftContent()} rightContent={renderForm()} />;
};
