import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';

export const Collapsible = ({ expanded, children }: PropsWithChildren<{ expanded?: boolean }>) => {
  const childRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useLayoutEffect(() => {
    const el = childRef.current;

    if (!el) {
      return;
    }

    const resizeObserver = new ResizeObserver((entry) => {
      if (expanded) {
        setMaxHeight(entry[0].target.scrollHeight);
      } else {
        setMaxHeight(0);
      }
    });

    resizeObserver.observe(childRef.current.children[0]);

    return () => {
      resizeObserver.disconnect();
    };
  }, [expanded, childRef.current?.children[0]?.scrollHeight]);

  return (
    <div ref={childRef} style={{ maxHeight }} className="relative overflow-scroll transition-[max-height] duration-300">
      {children}
    </div>
  );
};
