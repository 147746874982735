import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui/card';
import { Button } from '@shadcn/ui/button';
import { ScrollArea } from '@shadcn/ui/scroll-area';
import { X } from 'lucide-react';
import Benchmarks from '../../pages/project/tabs/Benchmarks';

interface ReviewTablesProps {
  projectId: string;
  onClose: () => void;
}

export const ReviewTables = ({ projectId, onClose }: ReviewTablesProps) => {
  return (
    <Card className="mb-4">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Data Review</CardTitle>
        <Button variant="ghost" size="icon" onClick={onClose} className="h-8 w-8 rounded-full">
          <X className="h-4 w-4" />
        </Button>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[500px]">
          <Benchmarks projectId={projectId} />
        </ScrollArea>
      </CardContent>
    </Card>
  );
};
