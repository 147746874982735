import { Input, InputProps, Label } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import { useField } from 'formik';
import * as React from 'react';

export const FormikInputWrapper = React.forwardRef<HTMLInputElement, InputProps & { label?: string }>(
  ({ className, label, ...props }, ref) => {
    const [field, meta] = useField({ ...props, name: `${props.name}` });
    const errorText = meta.error && meta.touched ? meta.error : '';

    return (
      <div className="flex flex-col gap-2">
        {label ? <Label>{label}</Label> : null}
        <Input
          {...field}
          {...props}
          className={cn(className, {
            'border border-destructive focus-visible:outline-none focus-visible:ring-destructive': errorText,
          })}
          ref={ref}
        />
        {errorText ? <p className="bg-destructive/25 text-destructive-foreground">{errorText}</p> : null}
      </div>
    );
  }
);
FormikInputWrapper.displayName = 'FormikInputWrapper';
