import type { PropertyMetrics, FlattenedAlert } from 'app/services/energy-star-services';
import { LoadingSpinner } from 'app/components/ui/LoadingSpinner';
import { AlertTriangle } from 'lucide-react';

type EnergyStarScoreCardProps = {
  score?: PropertyMetrics;
  noScoreReason?: FlattenedAlert[];
  theme: any;
};
export const EnergyStarScoreCard = ({ score, noScoreReason, theme }: EnergyStarScoreCardProps) => (
  <div className="mb-8 rounded-xl bg-white/10 p-6 shadow-lg backdrop-blur-lg backdrop-filter">
    <div className="mb-4 flex items-center gap-3">
      <h2 className="text-xl font-bold" style={{ color: theme.text.primary }}>
        Energy Star Score
      </h2>
    </div>

    {score === undefined && noScoreReason === undefined ? (
      <div className="flex h-24 items-center justify-center">
        <LoadingSpinner />
      </div>
    ) : score?.score ? (
      <div className="flex items-center gap-4">
        <div className="flex h-24 w-24 items-center justify-center rounded-full bg-primary text-3xl font-bold text-primary-foreground">
          {Math.round(score.score)}
        </div>
        <div>
          <p className="text-md font-medium" style={{ color: theme.text.primary }}>
            Energy Star Score (as of December 2023)
          </p>
        </div>
      </div>
    ) : (
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <AlertTriangle className="h-5 w-5 text-amber-500" />
          <p className="text-lg font-medium" style={{ color: theme.text.primary }}>
            No Energy Star score available
          </p>
        </div>

        {noScoreReason && noScoreReason.length > 0 && (
          <div className="space-y-4">
            <p className="text-sm font-medium uppercase tracking-wider" style={{ color: theme.text.secondary }}>
              Reasons
            </p>
            {noScoreReason.map((reason, index) => (
              <div key={index} className="overflow-hidden rounded-lg border border-white/10">
                <div
                  className="border-b border-white/10 bg-white/10 p-4"
                  dangerouslySetInnerHTML={{ __html: reason.name }}
                />
                {reason.description && (
                  <div
                    className="p-4 text-sm leading-relaxed"
                    style={{ color: theme.text.secondary }}
                    dangerouslySetInnerHTML={{ __html: reason.description }}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    )}
  </div>
);
