import { Dispatch, SetStateAction, useState } from 'react';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import { useToast } from '@shadcn/hooks/use-toast';
import {
  Button,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogHeader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shadcn/ui';
import reportServices from 'app/services/report-services';

interface NewReportModalProps {
  projectId: string;
  setRefreshTrigger: Dispatch<SetStateAction<number>>;
  handleClose: () => void;
}

export const NewReportModal = ({ projectId, setRefreshTrigger, handleClose }: NewReportModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newReportName, setNewReportName] = useState('');
  const [newOutputType, setNewOutputType] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>({});

  const { toast } = useToast();

  const handleCreateReport = async () => {
    if (validateForm()) {
      setIsSubmitting(true);

      try {
        const report = await reportServices.create({
          project: projectId,
          name: newReportName.trim(),
          outputType: newOutputType,
        });

        handleClose();
        toast({
          description: 'Report created successfully.',
          variant: 'positive',
        });
        setRefreshTrigger((prev) => prev + 1);
        return report;
      } catch (error) {
        toast({
          description: 'Error generating report.',
          variant: 'destructive',
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast({
        description: 'Please correct the errors before submitting.',
        variant: 'destructive',
      });
    }
  };

  const validateForm = () => {
    const tempErrors = {
      newReportName: newReportName.trim() ? '' : 'Report name is required',
      newOutputType: newOutputType ? '' : 'Output type is required',
    };

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Generate New Report</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <InputWrapper
          autoFocus
          id="name"
          label="Report Name"
          type="text"
          value={newReportName}
          onChange={(e) => setNewReportName(e.target.value)}
          error={!!errors.newReportName}
          helperText={errors.newReportName}
        />

        <Select value={newOutputType} onValueChange={(v) => setNewOutputType(v)}>
          <SelectTrigger>
            <SelectValue placeholder="Report type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="xlsx">XLSX</SelectItem>
            <SelectItem value="pdf">Local Law 97</SelectItem>
          </SelectContent>
        </Select>
        {errors.newOutputType ? (
          <p className="bg-destructive text-destructive-foreground">{errors.newOutputType}</p>
        ) : null}
      </DialogBody>
      <DialogFooter>
        <DialogClose asChild>
          <Button variant="secondary">Cancel</Button>
        </DialogClose>
        <Button loading={isSubmitting} variant="default" onClick={handleCreateReport} disabled={isSubmitting}>
          Generate
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};
