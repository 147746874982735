import { cn } from '@shadcn/utils';
import { useEffect, useState } from 'react';
import { useToast } from '@shadcn/hooks/use-toast';
import { Alert, Button, Dialog, DialogTitle, DialogHeader, DialogContent, ScrollArea } from '@shadcn/ui';
import { PricePlan } from './PricePlan';
import { FeaturesList } from './FeaturesList';
import billingServices from 'app/services/billing-services';
import { loadStripe } from '@stripe/stripe-js';
import { config } from 'app/config/main';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import featuresServices, { Plan } from 'app/services/features-services';

export const PricingPlan = () => {
  const stripePromise = loadStripe(config.env.STRIPE_PUBLISHABLE_KEY);

  const [currentBillPlan, setCurrentBillPlan] = useState<{ attributes?: { planName: string } }>({});
  const [paymentIntent, setPaymentIntent] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  const { toast } = useToast();

  const successSweetAlert = () => {
    toast({
      variant: 'positive',
      title: 'Success',
      description: 'Your organization is signed up for the Cosmos developer plan.',
      duration: 3000,
    });
  };

  const errorSweetAlert = () => {
    toast({
      variant: 'destructive',
      title: 'Error',
      description: 'Something went wrong finding this pricing plan. Please try again or email support@golivecosmos.com',
    });
  };

  const handleClose = () => {
    setOpen(false);
    setPaymentIntent(false);
  };

  const pricePlanIsSelected = (planName: Plan): boolean => {
    return currentBillPlan && currentBillPlan?.attributes?.planName === planName;
  };

  const selectPlan = async (event) => {
    setErrorMessage('');
    const {
      target: { id },
    } = event;

    try {
      const {
        attributes: { clientSecret },
      } = await billingServices.createBilling({ body: { planName: id } });
      if (clientSecret && id !== 'developer') {
        setPaymentIntent(true);
        setClientSecret(clientSecret);
        setOpen(true);
      } else {
        successSweetAlert();
        setSuccess(true);
      }
    } catch (error) {
      errorSweetAlert();
    }
  };

  const renderPricePlan = (planName: Plan, price: string, displayHeader: string) => {
    if (!isMounted) {
      return;
    }

    const isSelected = pricePlanIsSelected(planName);
    return (
      <PricePlan title={price} subheader={displayHeader} isSelected={isSelected}>
        <FeaturesList features={featuresServices.getFeaturesList(planName)} />
        <Button id={planName} className={cn('flex', { hidden: isSelected })} onClick={selectPlan}>
          Buy Now
        </Button>
      </PricePlan>
    );
  };

  useEffect(() => {
    async function fetchBillPlanFromUrl() {
      const billing = await billingServices.getBilling();
      if (!ignore) {
        setIsMounted(true);
        if (billing.id) {
          setCurrentBillPlan(billing);
        }
      }
    }

    let ignore = false;
    fetchBillPlanFromUrl();
    return () => {
      ignore = true;
    };
  }, [success, open]);

  return (
    <ScrollArea className="grow px-4">
      <h2 className="mb-8 pl-4">Pricing Plans</h2>
      {errorMessage ? (
        <Alert className="mb-6" variant="destructive">
          {errorMessage}
        </Alert>
      ) : null}

      <div className="mb-8 flex flex-row flex-nowrap gap-6 px-4">
        {renderPricePlan('basic', 'Free', 'Basic')}
        {renderPricePlan('pro', '$20/month', 'Pro')}
        {renderPricePlan('enterprise', '$150/month', 'Enterprise')}
      </div>

      {paymentIntent ? (
        <Dialog open={open} onOpenChange={(v) => (v ? setOpen(true) : handleClose())}>
          <DialogHeader>
            <DialogTitle>Payment Details</DialogTitle>
          </DialogHeader>
          <DialogContent>
            <div className="grid px-12">
              <Elements
                options={{
                  clientSecret,
                  appearance: {
                    theme: 'night',
                    variables: {
                      colorPrimary: 'hsl(var(--primary))',
                      colorText: 'hsl(var(--primary-foreground))',
                      spacingUnit: '4px',
                    },
                  },
                }}
                stripe={stripePromise}
              >
                <CheckoutForm />
              </Elements>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}
    </ScrollArea>
  );
};
